import * as React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PropTypes} from 'prop-types';
import { filterAlt } from '../../utils/filterAlt';

export const Image = ({ image, className }) => {

  let src = image.localFile?.publicURL ?? image.url ?? ''
  if (image.localFile?.childImageSharp) {
    let imageSharp = getImage(image.localFile.childImageSharp)
    src = imageSharp?.images?.fallback?.src
  }

  let breakpoint = false
  let breakpointSrc;
  if (image.thumbnails?.mobile) {
    breakpoint = true
    breakpointSrc = src
    src = image.thumbnails.mobile.localFile?.publicURL ?? image.thumbnails.mobile.url ?? ''
    if (image.thumbnails.mobile.localFile?.childImageSharp) {
      let imageSharpBreak = getImage(image.thumbnails.mobile.localFile.childImageSharp)
      src = imageSharpBreak?.images?.fallback?.src
    }
  }

  if (image.gatsbyImageData || image.localFile?.childImageSharp) {
    let data;
    if (image.localFile.childImageSharp) data = getImage(image.localFile.childImageSharp)
    else data = getImage(image.gatsbyImageData)
    return <GatsbyImage className={className} image={data} alt={filterAlt(image.alt || src || "")} />
  }

  return (
    <div className={className}>
      <picture>
        {breakpoint &&
          <source media="(min-width:681px)" srcSet={breakpointSrc} />}
        <img src={src} alt={filterAlt(image.alt || src || "")} />
      </picture>
    </div>
  )
}

Image.propTypes = {
	image: PropTypes.object,
	className: PropTypes.string
}