import * as React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { generateUrl } from '../utils/generateUrl';
import { PropTypes } from 'prop-types'


export const Breadcrumb = ({ activeDocMeta, color }) => {
	const { t } = useTranslation();
	const {lang, parents, title} = activeDocMeta;

	return (
		<div className={`c-breadcrumb ${color}`}>
			<Link className="c-breadcrumb-link o-routing-link" to={generateUrl(lang, '')}>{t('Infopunt e-leren')}</Link>
			{parents?.map((element) => (
				<Link key={element.url} className='c-breadcrumb-link icon-arrow-right o-routing-link' to={element.url}>{element.label}</Link>
			))}
			<div className="c-breadcrumb-link icon-arrow-right o-routing-link">{title}</div>
		</div>
	)
}

Breadcrumb.propTypes = {
	activeDocMeta: PropTypes.object,
	color: PropTypes.string
}