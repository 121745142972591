import * as React from 'react'
import { Download } from './Download';
import { PropTypes } from 'prop-types';

export const FileColumn = ({value}) => {
	return (
			<div className="c-filecolumn">
				<div className="c-filecolumn__title"><h2>{value?.primary?.column_title?.text}</h2></div>
				{value?.items.map((item) => (
					<Download key={`${value?.id}-${item.file.raw?.name}`} data={item} target='_blank' />
				))}
			</div>
	)
}

FileColumn.propTypes = {
  value: PropTypes.object
};
