import * as React from "react";
import { Link, graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { SliceZone } from "../components/SliceZone";
import { PropTypes } from "prop-types";
import { filterHtml } from "../utils/filterHtml";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { Hero } from "../components/Hero";

const Downloads = ({ data }) => {
  if (!data) return null;
  const page = data.prismicDownloads || {};
  const { lang, type, url } = page || {};
  const level2ItemsShown = data.allPrismicLevel2.nodes

  const title = page.data.title.text;

  const alternateLanguages = page.alternate_languages || [];
  const desc = page.data.meta_description || page.data.abstract?.text || page.data.intro?.text
  const activeDoc = { lang, type, url, alternateLanguages, title, desc };


  return (
    <Layout activeDocMeta={activeDoc} hideBreadcrumb>
			<Hero title={page.data.title.text} image={page.data.header_image} breadcrumbs={activeDoc}>
				<div className="o-intro-text -on-dark-bg"  dangerouslySetInnerHTML={{ __html: filterHtml(page.data.intro.html) }}></div>
			</Hero>

        <div className="o-wrapper c-navigation">
          <div className="o-3-col c-navigation-block">
            {level2ItemsShown.map((value) => {
              return (
                <article className={`c-navigation-block__item -${value.type}`} key={value.id}>
                  <h3 className="c-navigation-block__title">
                    <Link className="o-routing-link secondary" to={value.url+(value.type === "learning_overview" ? "#welkom" : "")}>{value.data.title.text}</Link>
                  </h3>
                  <div className="date">{new Date(value.last_publication_date).toLocaleDateString(lang, { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                  {value.data.abstract?.html && <div className="c-navigation-block__content" dangerouslySetInnerHTML={{ __html: filterHtml(value.data.abstract.html) }} />}
                  {value.data.description?.html && <div className="c-navigation-block__content" dangerouslySetInnerHTML={{ __html: filterHtml(value.data.description.html) }} />}
                </article>
              )
            })}
          </div>
        </div>

      <div className={`o-wrapper -spacing-y-m page-downloads`}>
        <SliceZone slices={page.data.body} />
        <div className="o-2-col">
          <SliceZone slices={page.data.body1} />
        </div>
      </div>
    </Layout>
  );
};

Downloads.propTypes = {
  data: PropTypes.object,
};

export default withPrismicPreview(Downloads);

export const query = graphql`
  query DownloadsQuery($lang: String, $id: String, $locale: String, $prismicId: ID!) {
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicLevel2(
      filter: { data: { parent: { id: { eq: $prismicId } } } }
      sort: { fields: data___weight, order: ASC }
    ) {
      nodes {
        _previewable
        type
        url
        id
        uid
        data {
          title {
            text
          }
          abstract {
            html
          }
          types {
            tag_type
          }
          subtheme
        }
        last_publication_date
      }
    }
    prismicDownloads(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      alternate_languages {
        document {
          ... on PrismicDownloads {
            url
          }
        }
        lang
      }
      lang
      url
      data {
        body1 {
          ... on PrismicDownloadsDataBody1Files {
            id
            slice_type
            primary {
              column_title {
                text
              }
            }
            items {
              file {
                url
                size
                localFile {
                  extension
                  publicURL
                  size
                }
                raw
                id
              }
              label
            }
          }
        }
        body {
          ... on PrismicDownloadsDataBodyPodcasts {
            id
            slice_type
            primary {
              title1 {
                text
              }
              description {
                html
              }
            }
            items {
              podcast_description {
                html
              }
              podcast_title
              thumbnail {
                alt
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 150
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                  publicURL
                }
              }
              podcast {
                id
                html
              }
              anchor
            }
          }
          ... on PrismicDownloadsDataBodyVideos {
            id
            slice_type
            primary {
              title {
                text
              }
              description {
                html
              }
            }
            items {
              publication_date
              title_link {
                link_type
                url
                type
                target
                document {
                  ... on PrismicLevel2Faq {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicEditorial {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicCookiePolicy {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicPrivacy {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicLevel2 {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicLevel3Step {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicLevel3Faq {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicCaseStudy {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicDisclaimer {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicHome {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicTheme {
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicCasesOverview {
                    id
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
              video_title
              video {
                id
                html
              }
              anchor
            }
          }
        }
        title {
          text
        }
        intro {
          html
          text
        }
        abstract {
          text
        }
        meta_description
        header_image {
          alt
          url
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
