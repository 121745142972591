import * as React from 'react'
import {graphql, Link} from "gatsby";
import { Layout } from '../components/Layout'
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { Textblock } from '../components/Slices/Textblock';
import { Image } from '../components/Slices/Image';
import { GatsbyLink } from '../components/Slices/GatsbyLink';
import { PropTypes } from 'prop-types';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';


const CaseStudy = ({ data, location }) => {
	const {t} = useTranslation();

	if (!data) return null;

	const page = data.prismicCaseStudy || {};
	const overviewData = data.prismicCasesOverview || {};
	const { lang, type, url } = page || {};
	const title = page.data.title?.text || "";

	const alternateLanguages = page.alternate_languages || []
	const parents = [{
		url: t('/case-studies'),
		label: overviewData.data?.title?.text
	}]
	const activeDoc = { lang, type, url, alternateLanguages, title, parents	}

	let publicationDate = new Date(page.last_publication_date).toLocaleDateString(lang, { year: 'numeric', month: 'long', day: 'numeric' })
	return (
		<Layout activeDocMeta={activeDoc}>
			<div className="case-study-page">
				<div className="o-wrapper">
					<Link className="o-routing-link secondary" id='back-link' to={`${(t('/case-studies'))}#${page.uid}`} state={{filters: location.state?.filters}}>
						<i className='action-back'></i>
						{t('Terug naar alle case studies')}</Link>
					<h1 className="c-intro-block__title">{page.data.title.text}</h1>
				</div>
				<div className={`o-wrapper o-2-col -sidearea`}>
					<div className="main">
						<Textblock id={page.id} title="" text={page.data.body.html} />
					</div>
					<div className="c-sidearea">
						<div className="c-info-block c-sticky-block">
							<div className="c-info-block__container c-sticky-block__container">
									{(page.data.links.length > 0 && page.data.links[0].link.url) && (
										<div className="c-info-block__buttons">
											<h5>{t('Vragen over de opleiding, klik hier')}</h5>
											{page.data.links.map((link) => (
												<div className="c-info-block__button" key={`opleiding-link-${link.label}`}>
													<GatsbyLink className="c-btn -primary -full-width" link={link.link} label={link.label} />
												</div>
											))}
										</div>
									)}
									<div className="c-info-block__content">
										<ul>
											<li>{t('Sector')}: {page.data.sector}</li>
											<li>{t('Organisator')}: {page.data.promotor}</li>
											<li>{t('Locatie')}: {page.data.provincie}</li>
										</ul>
										<div className="c-info-block__changed">
											<span>{t('Laatst gewijzigd op')} {publicationDate}</span>
										</div>
									</div>
							</div>
						</div>
						<div className="c-bottom-block">
							<Image className="c-block__image" image={overviewData.data.eu_logo} />
						</div>
					</div>
				</div>
				{(page.data.related_case_studies.length > 0 && page.data.related_case_studies[0].related_case_study.document) && (
					<div className="o-wrapper u-bg-grey-100" id='related-pages'>
						<h2>{t('Gelijkaardige case studies')}</h2>
						<ul>
							{page.data.related_case_studies.map((link) => (
								<li key={`related-case-link-${link.related_case_study.document.data.title.text}`}>
									<Link className="" to={link.related_case_study.document.url}>{link.related_case_study.document.data.title.text}</Link>
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		</Layout>
	)
}

CaseStudy.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object
}

export default withPrismicPreview(CaseStudy);

export const query = graphql`
	query CaseStudyQuery($lang: String, $id: String, $locale: String) {
		locales: allLocale(filter: {language: {eq: $locale}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		prismicCasesOverview(lang: {eq: $lang}) {
			_previewable
			data {
				title {
					text
				}
				eu_logo {
					alt
					localFile {
						publicURL
						id
					}
				}
			}
		}
		prismicCaseStudy(id: {eq: $id}, lang: {eq: $lang}) {
			_previewable
			type
			lang
			alternate_languages {
				document {
					... on PrismicCaseStudy {
						url
					}
				}
				lang
			}
			url
			id
			uid
			last_publication_date(locale: $locale)
			data {
        promotor
        provincie
        sector
        abstract {
          html
          text
        }
        body {
          html
          text
        }
        testimonial
        title {
          text
        }
        links {
          link {
            link_type
						id
            url
            type
            target
            document {
              ... on PrismicLevel2Faq {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicEditorial {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicCookiePolicy {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicPrivacy {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicLevel2 {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicLevel3Step {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicLevel3Faq {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicCaseStudy {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicDisclaimer {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicHome {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicTheme {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicCasesOverview {
                id
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
          label
        }
				related_case_studies {
					related_case_study {
						document {
							... on PrismicCaseStudy {
								data {
									title {
										text
									}
								}
								url
								id
							}
						}
					}
				}
      }
		}
	}
`;
