import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { PropTypes } from 'prop-types'


export const ProfileModalButtons = ({ step, profile, setStep, handleBack, handleClose }) => {
	const { t } = useTranslation();
	return (
    <div className="o-btn-group">
      {step === 0 && (
        <div className="start">
          <button onClick={() => setStep(step + 1)} className="c-btn -primary -medium -full-width@mobile">
            {t('Start')}
          </button>
        </div>
      )}
      {step === 4 && (
        <>
          <div className="back action-back">
            <button onClick={handleBack} >
              {t('Back to questions')}
            </button>
          </div>
          <div className="finish">
            <button onClick={() => handleClose()} className="c-btn -primary h2">
              {t('Ga naar mijn e-leren')}
            </button>
          </div>
        </>
      )}
      {(step < 4 && step > 1) && (
        <div className="previous">
          <button onClick={() => setStep(step - 1)} className="c-btn -primary -medium -full-width@mobile advance">
            {t('Previous')}
          </button>
        </div>
      )}
      {(step < 3 && step > 0) && (
        <div className="next">
          <button onClick={() => setStep(step + 1)} className="c-btn -primary -medium -full-width@mobile advance">
            {t('Next')}
          </button>
        </div>
      )}
      {(step === 3) && (
        <div className="next">
          <button disabled={!profile} title={profile ? "" : t('Vul een antwoord in op vraag 1')} onClick={() => setStep(step + 1)} className="c-btn -primary -medium -full-width@mobile advance">
            {t('Ga naar mijn resultaat')}
          </button>
        </div>
      )}
    </div>
  )
}

ProfileModalButtons.propTypes = {
	step: PropTypes.number,
	profile: PropTypes.object,
  setStep: PropTypes.func,
  handleBack: PropTypes.func,
  handleClose: PropTypes.func
}