import * as React from 'react'
import { Image } from '../Slices/Image';
import { PropTypes } from 'prop-types'

export const Author = ({ name, image, date }) => {
  return <div className='c-author'>
    <Image className="c-author__image" image={image} />
    <div className="c-author__info">
      <div className="c-author__name">{name}</div>
      <div className="c-author__date">{date}</div>
    </div>
  </div>;
};

Author.propTypes = {
  name: PropTypes.string,
  image: PropTypes.object,
  date: PropTypes.string,
};