function generateUrl(lang, url) {
	const languages = {
		'nl-be': '',
		'fr-be': 'fr',
		'en-eu': 'en'
	}

	url = url.replace(/[^a-z0-9-_/]/ig, '');

	let fullUrl = '';
	if (languages[lang]) {
		fullUrl += '/' + languages[lang];
	}
	if (url) {
		fullUrl += '/' + url;
	}

	return fullUrl || '/';
}


const getParentUid = (doc) => {
	if (doc?.data?.parent?.uid) {
		return doc.data.parent.uid;
	}
	return 'page';
};

const checkParentUid = (doc) => {
	if (getParentUid(doc) == 'page') {
		console.log('invalid parent for: ' + doc.id + ' : ' + doc.uid, doc.data?.parent);
		return false
	}
	return true
}

const defaultTypesToLinks = {
	'home': '',
	'disclaimer': 'disclaimer',
	'privacy': 'privacy',
	'cookie_policy': 'cookies',
	'cases_overview': 'case-studies',
}

exports.linkResolver = (doc) => {
  switch (doc.type) {
    case 'home': 
    case 'privacy': 
    case 'cookie_policy': 
    case 'cases_overview': 
    case 'disclaimer': {
      return generateUrl(doc.lang, defaultTypesToLinks[doc.type]);
    }

    case 'editorial':
    case 'theme':
    case 'community':
    case 'downloads': {
      return generateUrl(doc.lang, `${doc.uid}`);
    }

    case 'event': {
      return generateUrl(doc.lang, `community/${doc.uid}`);
    }

    case 'speaker': {
      return generateUrl(doc.lang, `spreker/${doc.uid}`);
    }

    case 'case_study': {
      return generateUrl(doc.lang, `case-study/${doc.uid}`);
    }

    case 'level_2': {
      if (doc.data?.blog) {
        return generateUrl(doc.lang, `blog/${doc.uid}`);
      }
			checkParentUid(doc)
      return generateUrl(doc.lang, `${getParentUid(doc)}/${doc.uid}`);
    }

    case 'level_2_faq':
    case 'learning_overview': {
      checkParentUid(doc)
      return generateUrl(doc.lang, `${getParentUid(doc)}/${doc.uid}`);
    }

    case 'level_3_faq':
    case 'level_3_step':
    case 'learning_detail': {
      return generateUrl(doc.lang, `${getParentUid(doc.data?.parent)}/${getParentUid(doc)}/${doc.uid}`);
    }

    default:
      return '/';
  }
};
