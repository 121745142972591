import * as React from "react";
import { graphql, Link } from "gatsby";
import { Layout } from "../components/Layout";
import { SliceZone } from "../components/SliceZone";
import { useTranslation } from "gatsby-plugin-react-i18next";
import slugify from "react-slugify";
import { PropTypes } from "prop-types";
import { filterHtml } from "../utils/filterHtml";
import { Author } from "../components/blog/Author";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const Level2 = ({ data }) => {
  const { t } = useTranslation();

  if (!data) return null;

  const page = data.prismicLevel2 || {};
  const { lang, type, url } = page || {};

  const relatedPages = Array.isArray(page.data.related_pages)
    ? page.data.related_pages.filter((page) => page.title1.text.length > 0)
    : [];
  const title = page.data.title.text;
  const desc = page.data.meta_description;
  const parents = [
    {
      url: page.data.parent.url,
      label: page.data.parent?.document?.data?.title?.text,
    },
  ];

  const alternateLanguages = page.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    title,
    desc,
    parents,
  };

  let colClass;
  switch (page.data.related_pages.length) {
    case 2:
      colClass = "o-2-col";
      break;
    case 3:
      colClass = "o-3-col";
      break;
    default:
      colClass = "";
  }
  let tocItems = [];
  for (const value of page.data.body) {
    if (value.primary?.title1?.text) {
      tocItems.push(value.primary.title1.text);
    }
  }

  return (
    <Layout activeDocMeta={activeDoc}>
      <div className="o-wrapper c-intro">
        <div className="o-1-col">
          <h1 className="c-intro-block__title">{page.data.title.text}</h1>
          <div
            className="o-intro-text"
            dangerouslySetInnerHTML={{
              __html: filterHtml(page.data.intro.html),
            }}
          />
        </div>
      </div>

      {data.allPrismicLevel3Step.nodes &&
        data.allPrismicLevel3Step.nodes.length > 0 && (
          <div className="o-wrapper">
            <ol className="o-3-col c-step-block">
              {data.allPrismicLevel3Step.nodes.map((value) => {
                return (
                  <li className="c-step-block__item" key={value.id}>
                    <div>
                      <h3>
                        <Link to={value.url}>{value.data.title.text}</Link>
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: filterHtml(value.data.abstract.html),
                        }}
                      />
                    </div>
                  </li>
                );
              })}
            </ol>
          </div>
        )}
      <div className={`o-wrapper o-2-col -sidearea`}>
        <div>
          <SliceZone slices={page.data.body} />

          {data.allPrismicLevel3Faq.nodes &&
            data.allPrismicLevel3Faq.nodes.length > 0 && (
              <div className="c-content-block">
                <h3>
                  {page.data.related_faq_title.text
                    ? page.data.related_faq_title.text
                    : t("FAQ")}
                </h3>
                <ul>
                  {data.allPrismicLevel3Faq.nodes.map((value) => {
                    return (
                      <li key={value.id}>
                        <Link to={value.url}>{value.data.title.text}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {page.data.author_name && page.data.author_image && (
              <Author name={page.data.author_name} image={page.data.author_image} date={new Date(page.last_publication_date).toLocaleDateString(lang, { year: 'numeric', month: 'short', day: 'numeric' })} />
            )}
        </div>
        <div className="is-sidearea">
          <div className="c-sidearea">
            <div className="c-sticky-block">
              <div className="c-sticky-block__container">
                {page.data.show_table_of_contents && tocItems.length > 0 && (
                  <div className="toc-block">
                    <h3>{t("toc")}</h3>
                    <ul>
                      {tocItems.map((value) => {
                        return (
                          <li key={"toc-" + value}>
                            <a href={"#" + slugify(value)}>{value}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                <a className="back-to-top__link" href="#content">
                  {t('Scroll back to top')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {relatedPages.length > 0 && (
        <div className="o-wrapper related related-pages u-bg-grey-100">
          {page.data.related_title.text && (
            <h2>{page.data.related_title.text}</h2>
          )}
          <div className={colClass}>
            {relatedPages.map((value) => {
              return (
                <div className="related-page" key={value}>
                  {value.title1.text && <h3>{value.title1.text}</h3>}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: filterHtml(value.text.html),
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Layout>
  );
};

Level2.propTypes = {
  data: PropTypes.object,
};

export default withPrismicPreview(Level2);

export const query = graphql`
  query MyQuery($lang: String, $id: String, $prismicId: ID!, $locale: String) {
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicLevel3Step(
      filter: {
        data: { parent: { id: { eq: $prismicId } } }
        lang: { eq: $lang }
      }
      sort: { fields: data___weight, order: ASC }
    ) {
      nodes {
        _previewable
        data {
          title {
            text
          }
          abstract {
            html
          }
        }
        url
        id
        prismicId
      }
    }
    allPrismicLevel3Faq(
      filter: {
        data: { parent: { id: { eq: $prismicId } } }
        lang: { eq: $lang }
      }
      sort: { fields: data___weight, order: ASC }
    ) {
      nodes {
        _previewable
        data {
          title {
            text
          }
        }
        url
        id
        prismicId
      }
    }
    prismicLevel2(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      data {
        body {
          ... on PrismicLevel2DataBodyPodcasts {
            id
            slice_type
            primary {
              title1 {
                text
              }
              description {
                html
              }
            }
            items {
              podcast_description {
                html
              }
              podcast_title
              thumbnail {
                alt
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 150
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                  publicURL
                }
              }
              podcast {
                id
                html
              }
              anchor
            }
          }
          ... on PrismicLevel2DataBodyImage {
            id
            primary {
              image {
                alt
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                  publicURL
                }
                thumbnails {
                  mobile {
                    url
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                      }
                      publicURL
                    }
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicLevel2DataBodyDownload {
            id
            slice_type
            primary {
              file {
                url
                size
                localFile {
                  extension
                  publicURL
                  size
                }
              }
              label
            }
          }
          ... on PrismicLevel2DataBodyQuote {
            id
            primary {
              author
              image {
                alt
                url
                localFile {
                  publicURL
                }
              }
              quote {
                html
              }
            }
            slice_type
          }
          ... on PrismicLevel2DataBodyTextblock {
            id
            primary {
              text {
                html
              }
              title1 {
                text
              }
            }
            slice_type
          }
        }
        meta_description
        title {
          text
        }
        intro {
          html
        }
        parent {
          url
          document {
            ... on PrismicTheme {
              id
              data {
                title {
                  text
                }
              }
            }
            ... on PrismicDownloads {
              id
              data {
                title {
                  text
                }
              }
            }
          }
        }
        related_pages {
          text {
            html
          }
          title1 {
            text
          }
        }
        related_title {
          text
        }
        related_faq_title {
          text
        }
        show_table_of_contents
        author_name
        author_image {
          alt
          url
          localFile {
            publicURL
          }
        }
      }
      last_publication_date
			type
      url
      alternate_languages {
        document {
          ... on PrismicLevel2 {
            url
          }
        }
        lang
      }
      lang
    }
  }
`;
