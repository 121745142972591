import * as React from "react";
import { Image } from "./Image";
import { PropTypes } from "prop-types";
import { filterHtml } from "../../utils/filterHtml";
import { GatsbyLink } from "./GatsbyLink";

export const CTABlock = ({data, items}) => {
  const {description, image, title1} = data
  return (
    <div className="cta-block">
      <div className="cta-block__image">
        <Image image={image} />
      </div>
      <div className="cta-block__info">
        <div className="cta-block__title">
          <h3>{title1?.text}</h3>
        </div>
        <div className="cta-block__description" dangerouslySetInnerHTML={{ __html: filterHtml(description?.html) }}/>
        <div className="o-btn-group">
          {items.map(({cta_link, cta_label}) => (
            <GatsbyLink key={cta_link.id} link={cta_link} label={cta_label} className="c-btn -primary" />
          ))}
        </div>
      </div>
    </div>
  );
};

CTABlock.propTypes = {
  data: PropTypes.object,
  items: PropTypes.array
};
