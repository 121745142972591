import * as React from "react";
import { PropTypes } from "prop-types";
import { filterHtml } from "../utils/filterHtml";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Image } from "./Slices/Image";

export const LinkedinPosts = ({ intro, posts }) => {
  const { t } = useTranslation();

  return (
    <section className="region region-linkedin-posts">
			<div className="o-stretch-wrapper u-bg-blue-100">
						<div className="o-wrapper u-mb0">
            <div className="o-2-col">
              {intro.map(({linkedin_description}) => (
                <div key={linkedin_description.html} dangerouslySetInnerHTML={{__html: filterHtml(linkedin_description.html)}}/>
              ))}
            </div>
						<div className="linkedin_post__wrapper">
							{posts.map((post) => (
								<a className="linkedin_post" key={post.linkedin_post_link.url} href={post.linkedin_post_link.url} target={post.linkedin_post_link.target} rel="noopener">
									<div className="linkedin_post__header">
										<div className="linkedin_post__title">
											<h4>{t('e-leren Vlaanderen')}</h4>
											<div className="linkedin_post__author">
												<span>{post.linkedin_post_author}</span>
											</div>
										</div>
										<div className="linkedin_post__logo"><i className="icon-social-linkedin"></i></div>
									</div>
									<div className="linkedin_post__description" dangerouslySetInnerHTML={{__html: filterHtml(post.linkedin_post_description.html)}}/>
									{post.linkedin_post_image.url && (
										<div className="linkedin_post__image">
											<Image image={post.linkedin_post_image} />
										</div>
									)}
								</a>
							))}
						</div>
					</div>
        </div>
    </section>
  );
};

LinkedinPosts.propTypes = {
  intro: PropTypes.array,
	posts: PropTypes.array
};
