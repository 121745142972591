import * as React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ProfileSwitcher } from '../components/ProfileSwitcher';
import {filterContent, filterLearningOverviewContent} from "../utils/filterContent";
import { AppContext } from "../context/AppContext";
import { Image } from '../components/Slices/Image';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

export const Profile = () => {
	const { t } = useTranslation();

	const queryData = useStaticQuery(graphql`
		query ProfileQuery {
			personalised_level2: allPrismicLevel2(
				filter: {data: {tag_profiles: {elemMatch: {profile: {uid: {nin: ["",null]}}}}}}
				sort: {fields: data___weight, order: ASC}
			) {
				nodes {
					_previewable
					url
					id
					data {
						title {
							text
						}
						abstract {
							html
						}
						parent {
							uid
						}
						tag_profiles {
							profile {
								uid
								document {
									... on PrismicVisitorProfile {
										id
										data {
											name
										}
									}
								}
							}
						}
						types {
							tag_type
						}
					}
				}
			}
			personalised_learning_overviews: allPrismicLearningOverview {
				nodes {
					_previewable
					url
					id
					data {
						title {
							text
						}
						description {
							text
						}
						image {
							url
							alt
							localFile {
								publicURL
							}
						}
						body {
							... on PrismicLearningOverviewDataBodyChapter {
								id
								items {
									accessible
								}
							}
						}
					}
				}
			}
		}
	`);
	const { data } = useMergePrismicPreviewData(queryData)

	const context = React.useContext(AppContext);
	const profileLevel2Limit = 3;
	const profileLevel2 = filterContent(data.personalised_level2.nodes || [], context);
	const profileLearningOverviews = filterLearningOverviewContent(data.personalised_learning_overviews.nodes || [], context);

	const [isClient, setClient] = React.useState(false)
	React.useEffect(() => {
		setClient(true)
	}, [])
	const key = isClient ? `client` : `server`

	if (!context.showProfile) {
		return (
			<div className="o-stretch-wrapper u-bg-blue-100" key={key}>
				<div className="o-wrapper -spacing-y-0 u-display-flex u-justify-content-between u-flex-wrap">
					<h2>{t('Info op maat gemaakt voor jou? Ontdek het met enkele vragen.')}</h2>
					<button onClick={() => context.setHideProfileModal(false)} className='c-btn -primary -medium -full-width@mobile'>{t('Start de test')}</button>
				</div>
			</div>
		)
	}

	return (
		(profileLevel2.length > 0 || profileLearningOverviews.length > 0) &&
			<div className="featured-content o-stretch-wrapper u-bg-blue-100 u-pt1" key={key}>
				<div className="o-wrapper -spacing-y-m o-2-col gap-xxxl -dist-md-3-1 gap-l@mobile -sidearea -sidearea-below u-pt0">
					<div className='featured-content__body'>
						<h2 className='h1 featured-content__title'>{t('Interesting for you')}</h2>
						{profileLevel2.filter((value,index) => (index < profileLevel2Limit)).map((value, index) => (
							<article className={"c-navigation-block__item" + (index > 0 ? ' u-mt2' : '')} key={value.id}>
								<h3 className="c-navigation-block__title u-mb1">
									<Link className="o-routing-link blue" to={value.url}>{value.data.title.text}</Link>
								</h3>
								<div className="c-navigation-block__content">
									<div dangerouslySetInnerHTML={{ __html: value.data.abstract.html}} />
								</div>
							</article>
						))}
					</div>
					<div className="is-sidearea">
						<div className="c-sidearea">
							<div className="u-display-flex u-flex-direction-column">
								<div className="u-align-self-end"><ProfileSwitcher /></div>
							</div>
							{profileLearningOverviews.length > 0 &&
								<div className="c-learning-previews">
									<h2>{t('In de kijker')}</h2>
									{profileLearningOverviews.map((value) => {
										return (
											<article className={`c-learning-preview u-bg-white u-ml0 u-mr0 u-mb1`} key={value.id}>
											<h3 className="c-navigation-block__title">
												<Link className="o-routing-link" to={value.url+"#welkom"}>{value.data?.title.text}</Link>
											</h3>
												<Link to={value.url+"#welkom"}>
													<Image className="c-learning-preview__image" image={value.data?.image} />
												</Link>
												<div className="c-learning-preview__content">
													<p>{value.data?.description.text}</p>
												</div>
											</article>
										)
									})}
								</div>
							}
						</div>
					</div>
				</div>
			</div>
	)
}