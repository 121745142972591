import * as React from 'react'
import { Podcast } from './Podcast';
import { PropTypes } from 'prop-types'
import { filterHtml } from '../../utils/filterHtml';

export const PodcastList = ({value}) => {
	return (
			<div className="c-podcastlist">
				<div className="c-podcastlist__title"><h2>{value.primary?.title1?.text}</h2></div>
				<div className="c-podcastlist__description" dangerouslySetInnerHTML={{__html: filterHtml(value.primary?.description?.html)}}></div>
				{value.items?.map((item) => (
					<Podcast key={value.id + item.podcast?.id} item={item}/>
				))}
			</div>
	)
}

PodcastList.propTypes = {
	value: PropTypes.object
}