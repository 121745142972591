import * as React from "react";
import { PropTypes } from "prop-types";
import { filterHtml } from "../../utils/filterHtml";
import { Image } from "./Image";
import { Download } from "./Download";
import { Link } from "gatsby";

export const TableRow = ({ value }) => {
  const isDropdown =
    (value.primary.table_row_description &&
      value.primary.table_row_description.html !== "") ||
    value.items.length > 0;
  const categories = [
    ...new Set(value.items.map((item) => item.person_category)),
  ];

  return (
    <div className={`c-tablerow ${isDropdown ? "dropdown" : ""}`}>
      <div className="c-tablerow__time">
        <span>{value.primary.time}</span>
      </div>
      <details className="c-tablerow__main" open={value.primary.display_open}>
        <summary className="c-tablerow__summary">
          {value.primary.tag && (
            <div className="c-tablerow__tag">
              <span>{value.primary.tag}</span>
            </div>
          )}
          <div className="c-tablerow__title">
            <h5>{value.primary.table_row_title.text}</h5>
          </div>
          <span className="c-tablerow__marker icon-arrow-down"></span>
        </summary>
        {value.primary.table_row_description &&
          value.primary.table_row_description.html !== "" && (
            <div
              className="c-tablerow__description"
              dangerouslySetInnerHTML={{
                __html: filterHtml(value.primary.table_row_description.html),
              }}
            />
          )}
        {value.items.length > 0 &&
          categories.map((category) => (
            <div
              className="c-tablerow__items"
              key={value.primary.table_row_title.text + category}
            >
              {category && (
                <span className="c-tablerow__category">{category}:</span>
              )}
              <div className="c-tablerow__persons">
                {value.items
                  .filter((item) => item.person_category === category)
                  .map((item) => {
                    const children = (
                      <>
                        {item.person_image?.url && <Image
                          className="c-tablerow__person__image"
                          image={item.person_image}
                        />}
                        <span className="c-tablerow__person__name">
                          {item.person_title.text}
                        </span>
                      </>
                    );
                    if (item.person_link?.url)
                      return (
                        <Link
                          to={item.person_link?.url}
                          className="c-tablerow__person"
                          key={item.person_title.text}
                        >
                          {children}
                        </Link>
                      );
                    else
                      return (
                        <a
                          href={
                            "#" +
                            item.person_title.text
                              .replaceAll(" ", "-")
                              .toLowerCase()
                          }
                          className="c-tablerow__person"
                          key={item.person_title.text}
                        >
                          {children}
                        </a>
                      );
                  })}
              </div>
            </div>
          ))}
        {value.primary.download?.url && (
          <div className="c-tablerow__download">
            <Download target="_blank" data={value.primary} />
          </div>
        )}
      </details>
    </div>
  );
};

TableRow.propTypes = {
  value: PropTypes.object,
};
