import { AppProvider } from "./src/context/AppContext";
import React from "react";
import { initializeAndTrackGoogleTagManagerOther } from "./src/utils/addGTM";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";
import { linkResolver } from "./src/utils/linkResolver";
import { typeToTemplateList } from "./src/utils/prismicUtils";
export const onRouteUpdate = function (props) {
  const { location } = props;
  scrollToAnchor(location);
  initializeAndTrackGoogleTagManagerOther(
    window.gatsbyPluginGDPRCookiesOptions.googleTagManagerIO,
    location
  );
};

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, mainNavHeight = 120) {
  if (location?.hash) {
    let item = document.getElementById(
      `${location.hash.substr(1, location.hash.length)}`
    );
    if (location.hash == "#video") {
      item = document.querySelector('div[data-oembed-type="video"]');
    }
    if (item) {
      const position = item.offsetTop;

      window.scrollTo({
        top: position - mainNavHeight,
        behavior: "smooth",
      });
    }
  }

  return true;
}

export const wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      <PrismicPreviewProvider
        repositoryConfigs={[
          {
            repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
            linkResolver,
            componentResolver: componentResolverFromMap(typeToTemplateList),
          },
        ]}
      >
        {element}
      </PrismicPreviewProvider>
    </AppProvider>
  );
};
