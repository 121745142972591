import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Header } from './Header'
import { Footer } from './Footer'
import { Helmet } from 'react-helmet'
import { CookieModal } from './CookieModal';
import { ProfileModal } from './ProfileModal';
import { Breadcrumb } from './Breadcrumb'
import { StickyButton } from './StickyButton';
import {langLocaleMap} from '../config/lang'
import '../styles/site.scss';
import { PropTypes } from 'prop-types';
import { ExitIntent } from './ExitIntent'

export const Layout = ({ children, activeDocMeta, hideCookieNotification, pageData, showSearch, hideBreadcrumb = false }) => {
	const queryData = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
        }
      }    
    }
  `);

	if (!activeDocMeta?.title && pageData?.data) {
		const desc = pageData.data.meta_description || "";
		const { lang, type, url } = pageData || {}
		const alternateLanguages = pageData.alternate_languages || []
		const title = pageData.data.title?.text || "";
		activeDocMeta = {
			lang,
			type,
			url,
			alternateLanguages,
			desc,
			title
		}
	}

	let overrideShow = false
	let location = typeof window !== "undefined" ? window.location : ""
	if (location?.search === "?cookie-policy") {
		hideCookieNotification = false;
		overrideShow = true
	}
	return (
		<>
			<Helmet htmlAttributes={{lang: langLocaleMap[activeDocMeta.lang] ?? activeDocMeta.lang ?? 'nl'}}>
				<meta charSet="utf-8" />
				<title>{(activeDocMeta.title ? activeDocMeta.title + ' - ' : '') + queryData.site.siteMetadata.title}</title>
				<meta
					name="description"
					content={activeDocMeta.desc ? activeDocMeta.desc : ''}
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<link rel="shortcut icon" href="/favicons/icon-32x32.png" type="image/png" />
			</Helmet>
			<div className="dialog-off-canvas-main-canvas" data-off-canvas-main-canvas="">
				<Header activeDocMeta={activeDocMeta} />
				<StickyButton />
				<section className="page-container" id="main-content">
					<section id="content" className="section--content">
						<main role="main">
							{!hideBreadcrumb && 
							<div className='o-wrapper -spacing-y-m'>
								<Breadcrumb activeDocMeta={activeDocMeta} />
							</div>
							}
							{children}
						</main>
					</section>
				</section>
				<Footer activeDocMeta={activeDocMeta} showSearch={showSearch} />
			</div>
			{(hideCookieNotification === undefined || hideCookieNotification === false) &&
				<CookieModal override={overrideShow}/>
			}
			<ProfileModal />
			<ExitIntent />
			{process.env.NODE_ENV !== 'production' && process.env.ENABLE_GATSBY_REFRESH_ENDPOINT && 
				<button className='c-btn -primary c-refresh-button' onClick={() => {fetch('/__refresh', {method: 'POST'})}}>Refresh</button>
			}
		</>
	);
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  activeDocMeta: PropTypes.object,
  hideCookieNotification: PropTypes.bool,
	pageData: PropTypes.object,
	showSearch: PropTypes.bool,
	hideBreadcrumb: PropTypes.bool
};