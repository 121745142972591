import * as React from 'react'
import { GatsbyLink } from './GatsbyLink'
import { useTranslation } from "gatsby-plugin-react-i18next";
import { PropTypes } from 'prop-types'
import { filterHtml } from '../../utils/filterHtml';

export const Video = ({item}) => {
  const { t } = useTranslation();
  let date = new Date(item.publication_date);
  let publicationDate = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`
	return (
    <div className="c-video" id={item.anchor?.replace(' ','-')}>
      <div className="c-video__video" dangerouslySetInnerHTML={{__html: filterHtml(item.video?.html)}}></div>
      <div className="c-video__description">
        <GatsbyLink className="c-video__link o-routing-link" link={item.title_link} label={item.video_title} />
        <div className="c-video__date"><span>{t('Published on {{date}}', {date: publicationDate})}</span></div>
      </div>
    </div>
	)
}

Video.propTypes = {
	item: PropTypes.object
}