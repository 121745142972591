import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { Layout } from '../components/Layout'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { filterHtml } from '../utils/filterHtml';
import { Image } from '../components/Slices/Image';
import { PropTypes } from 'prop-types'
import {filterLearningOverviewContent} from "../utils/filterContent";
import { GatsbyLink } from "../components/Slices/GatsbyLink";
import { Filter } from '../components/theme/Filter';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

// markup
const Theme = ({ data }) => {
  const { t } = useTranslation();

	const theme = data.prismicTheme || {}
	const title = theme.data.title.text;
	const desc = theme.data.meta_description;

	const { lang, type, url } = theme || {}
	const alternateLanguages = theme.alternate_languages || []
	const activeDoc = {
		lang,
		type,
		url,
		alternateLanguages,
		title,
		desc
	};
  
  const level2ItemsShown = data.allPrismicLevel2.nodes.concat(filterLearningOverviewContent(data.allPrismicLearningOverview.nodes));
  const [activeSubtheme, setActiveSubtheme] = React.useState('')
  const [activeTypes, setActiveTypes] = React.useState([])

  const updateFilter = React.useCallback((value) => {
      switch (value.filter) {
        case 'subtheme':
          setActiveSubtheme(value.activeSubtheme)
          break;
        case 'type':
          if (value.value) setActiveTypes([...activeTypes, value.name])
          else setActiveTypes(activeTypes.filter(e => e !== value.name))
          break;
        default:
          break;
      }
  }, [activeTypes])

	React.useEffect(() => {
    updateFilter("");
	}, [updateFilter]);

  const tagInfo = {
    "Text": {
      icon: "article",
      label: t('Artikel')
    },
    "Video": {
      icon: "video",
      label: t('Video')
    },
    "Interactive": {
      icon: "learn",
      label: t('E-learning')
    },
    "Live": {
      icon: "webinar",
      label: t('Live')
    },
    "Audio": {
      icon: "podcast",
      label: t('Podcast')
    },
    "Blog": {
      icon: "blog",
      label: t('Blog')
    },
  }
  
  return (
		<Layout activeDocMeta={activeDoc}>
			<div className="o-wrapper c-intro">
				<h1 className="c-intro-block__title">{theme.data.title.text}</h1>
				<div className="o-intro-text" dangerouslySetInnerHTML={{ __html: filterHtml(theme.data.intro.html) }} />
			</div>
			<div className="o-wrapper c-navigation">
          <Filter items={level2ItemsShown} onFiltersChange={updateFilter} tagInfo={tagInfo} />
				<div className={`o-3-col c-navigation-block`}>
					{ level2ItemsShown.map((value) => {
            if (value.type === "learning_overview") value.data.types = [{tag_type: "Interactive"}]
            else if (value.type === "level_2" && value.data.blog) value.data.types = [{tag_type: 'Blog'}]
            if ((activeSubtheme !== '' && activeSubtheme !== value.data.subtheme) || (activeTypes.length !== 0 && !activeTypes.includes(value.data.types[0].tag_type))) return <></>;

						return (
							<article className={`c-navigation-block__item -${value.type}`} key={value.id}>
								<h3 className="c-navigation-block__title">
									<Link className="o-routing-link secondary" to={value.url+(value.type === "learning_overview" ? "#welkom" : "")}>{value.data.title.text}</Link>
								</h3>
                <div className="blog-info">{value.data.author_name ? value.data.author_name + " - " : ""}{new Date(value.last_publication_date).toLocaleDateString(lang, { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                {value.data.types?.length !== 0 && value.data.types[0].tag_type &&
                  <div className={`tag ${value.data.types[0].tag_type}`}>
                    <i className={`icon-${tagInfo[value.data.types[0].tag_type].icon}`}></i>
                    <span>{tagInfo[value.data.types[0].tag_type].label}</span>
                  </div>
                }
								{value.data.abstract?.html && <div className="c-navigation-block__content" dangerouslySetInnerHTML={{ __html: filterHtml(value.data.abstract.html) }} />}
								{!value.data.abstract?.html && value.data.description?.html && <div className="c-navigation-block__content" dangerouslySetInnerHTML={{ __html: filterHtml(value.data.description.html) }} />}
							</article>
						)
					})}
				</div>
			</div>
			{(theme.data.banner_title.text || theme.data.banner_text.html) &&
				<div className="c-rubriek o-wrapper u-bg-blue-100">
					<div className="o-2-col -sidearea  c-rubriek-block ">
						<div className="c-rubriek-block__textblock">
							{theme.data.banner_title.text && <h2 className="h1">{theme.data.banner_title.text}</h2>}
							{theme.data.banner_text.html && <div className="o-intro-text" dangerouslySetInnerHTML={{__html: theme.data.banner_text.html}}/>}
							{theme.data.banner_cta_label &&
								<div className="o-btn-group">
                  <GatsbyLink link={theme.data.banner_cta_link} label={theme.data.banner_cta_label} className="c-btn -primary" />
								</div>
							}
						</div>
						{theme.data.banner_image.localFile &&
						<div className="c-rubriek-block__imageblock">
							<Image key={theme.data.banner_image.id} className="c-rubriek-block__image" image={theme.data.banner_image} />
						</div>
						}
					</div>
				</div>
			}
		</Layout>
	)
}

Theme.propTypes = {
  data: PropTypes.object
}


export const query = graphql`
  query themeQuery(
    $lang: String
    $id: String
    $prismicId: ID!
    $locale: String
  ) {
    prismicTheme(lang: { eq: $lang }, id: { eq: $id }) {
      _previewable
      type
      alternate_languages {
        document {
          ... on PrismicTheme {
            url
          }
        }
        lang
      }
      lang
      url
      data {
        title {
          text
        }
        meta_description
        intro {
          html
        }
        banner_title {
          text
        }
        banner_text {
          html
        }
        banner_image {
          url
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 900
                layout: CONSTRAINED
                placeholder: NONE
              )
            }
            publicURL
          }
          thumbnails {
            mobile {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1230
                    layout: CONSTRAINED
                    placeholder: NONE
                  )
                }
                publicURL
              }
            }
          }
        }
        banner_cta_link {
          url
          localFile {
            publicURL
          }
          link_type
        }
        banner_cta_label
      }
    }
    allPrismicLevel2(
      filter: { data: { parent: { id: { eq: $prismicId } } } }
      sort: { fields: data___weight, order: ASC }
    ) {
      nodes {
        _previewable
        type
        url
        id
        uid
        data {
          title {
            text
          }
          blog
          author_name
          abstract {
            html
          }
          types {
            tag_type
          }
          subtheme
        }
        last_publication_date
      }
    }
    allPrismicLearningOverview(filter: { data: { parent: { id: { eq: $prismicId } } } }) {
      nodes {
        _previewable
        type
        url
        id
        data {
          title {
            text
          }
          abstract {
            html
          }
          description {
            html
          }
          body {
            ... on PrismicLearningOverviewDataBodyChapter {
              id
              items {
                accessible
              }
            }
          }
          subtheme
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withPrismicPreview(Theme)
