export const locales = ['nl', 'fr', 'en'];

export const localeLangMap = {
	'nl': 'nl-be',
	'fr': 'fr-be',
	'en': 'en-eu'
}

export const langLocaleMap = {
	'nl-be': 'nl',
	'fr-be': 'fr',
	'en-eu': 'en'
}
