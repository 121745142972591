import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { generateUrl } from "../utils/generateUrl";
import { SearchWrapper } from "./SearchWrapper";
import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyLink } from "./Slices/GatsbyLink";
import { PropTypes } from 'prop-types'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

export const Footer = ({ activeDocMeta, showSearch = true}) => {
  const { t } = useTranslation();

  let translations = {};
  if (activeDocMeta.url) {
    translations[activeDocMeta.lang] = activeDocMeta.url;
  }

  if (activeDocMeta.alternateLanguages) {
    activeDocMeta.alternateLanguages.forEach((value) => {
      if (value.document) {
        translations[value.lang] = value.document.url;
      }
    });
  }

  const queryData = useStaticQuery(graphql`
    query SiteFooterQuery($lang: String) {
      prismicFooter(lang: { eq: $lang }) {
        _previewable
        data {
          bottom_links {
            label1
            link {
              id
              link_type
              url
              type
              target
              document {
                ... on PrismicLevel2Faq {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicEditorial {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicCookiePolicy {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPrivacy {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicLevel2 {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicLevel3Step {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicLevel3Faq {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicCaseStudy {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicDisclaimer {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicHome {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicTheme {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicCasesOverview {
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          body {
            ... on PrismicFooterDataBodyColumn {
              id
              primary {
                title1
              }
              items {
                link {
                  id
                  url
                  target
                  type
                  link_type
                  document {
                    ... on PrismicLevel2Faq {
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicEditorial {
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicCookiePolicy {
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicPrivacy {
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicLevel2 {
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicLevel3Step {
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicLevel3Faq {
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicCaseStudy {
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicDisclaimer {
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicHome {
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicTheme {
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicCasesOverview {
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
                label
              }
            }
          }
          buttons {
            label
            link {
              id
              link_type
              url
              type
              target
              document {
                ... on PrismicLevel2Faq {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicEditorial {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicCookiePolicy {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPrivacy {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicLevel2 {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicLevel3Step {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicLevel3Faq {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicCaseStudy {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicDisclaimer {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicHome {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicTheme {
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicCasesOverview {
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          label
          title
        }
      }
    }
  `);

  const { data } = useMergePrismicPreviewData(queryData)

  return (
    <footer className="c-footer" role="contentinfo">
      {showSearch && <SearchWrapper source="footer" />}

      <div className="o-wrapper">
        <nav id="footer-breadcrumbs" aria-label={t("Je bent hier")}>
          <ul className="c-footer__breadcrumb">
            <li>
              <Link to={generateUrl(activeDocMeta.lang, '')}><h2>{data.prismicFooter.data?.title}</h2></Link>
            </li>
            {activeDocMeta.title && 
              <li className="c-footer__breadcrumb__item" aria-current="page">{activeDocMeta.title}</li>
            }
          </ul>
        </nav>

        <div className="mt0 o-2-col -sidearea">
          <nav className="c-footer__link-container" aria-label={t("Footer navigatie")}>
            <div className="u-reset-list c-footer__link-container__menu">
              {data.prismicFooter.data?.body?.map((list) => {
                return (
                  <div key={`menu-list-${list.id}`} className="c-footer__link-container-column">
                    <div className="c-footer__link-container__title">
                      <h3>{list.primary.title1}</h3>
                    </div>
                    <div className="c-footer__link-container__link_list">
                      {list.items.map((link) => {
                        return (
                          <div className="c-footer__link-container__link" key={`menu-link-${list.id}-${link.label}`}>
                            <GatsbyLink link={link.link} label={link.label} className="c-footer__link" />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </nav>
          <div className="c-footer__contact">
            <nav className="c-footer__newsletter" aria-label="Newsletter">
              <h4 className="c-footer__newsletter__title u-mb1">{t('Blijf op de hoogte')}</h4>
              <p className="u-text-color-white">{t('Schrijf je in op de nieuwsbrief')}</p>
              <Link to={`/${t("nieuwsbrief")}`} className="c-btn -primary -small u-mb2">{t('Ik schrijf mij in')}</Link>
              <p>{t('Word lid van onze LinkedIn-groep')}</p>
              <GatsbyLink link={{url: "https://www.linkedin.com/groups/12916020/", target: "_blank"}} label={t('LinkedIn-groep')} className="c-btn -primary -small action-linkedin" />
            </nav>
            <nav className="c-footer__contact__nav" aria-label="Contact">
              <h3>{t('Heb je een vraag?')}</h3>
              <p>{t('Mail')} <a className="email" href="mailto:e-leren@vdab.be" target="_blank" rel="noopener noreferrer">e-leren@vdab.be</a></p>
              <GatsbyLink link={{url: "mailto:e-leren@vdab.be", target: "_blank"}} label={t('Mail ons')} className="c-btn -primary -small action-mail" />
            </nav>
          </div>
        </div>
        {(data.prismicFooter.data?.buttons?.length) > 0 && (
          <div className="c-footer__button-group">
            {data.prismicFooter.data.buttons.map((link) => {
              return (
                <GatsbyLink key={`button-link-${link.label}`} link={link.link} label={link.label} className="c-footer__button-group__button" />
              );
            })}
          </div>
        )}
      </div>
      <div className="c-footer__flanders">
        <div className="c-footer__flanders__container o-wrapper">
          <a href="https://www.vlaanderen.be/" className="c-footer__flanders__backslash-before -logo-backslash c-footer__flanders__link -logo-link">
            {data.prismicFooter.data?.label}
          </a>
          <div className="c-footer__flanders__container -right-side">
            {data.prismicFooter.data?.bottom_links?.map((link) => {return (
              <GatsbyLink key={`bottom-menu-link-${link.link.id}`} link={link.link} label={link.label1} className="c-footer__flanders__backslash-before c-footer__flanders__link" />
            )})}
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
	activeDocMeta: PropTypes.object,
  showSearch: PropTypes.bool
}