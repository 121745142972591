import * as React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { PropTypes } from "prop-types";
import { Hero } from "../components/Hero";
import { filterHtml } from "../utils/filterHtml";
import { GatsbyLink } from "../components/Slices/GatsbyLink";
import { EventTeasers } from "../components/EventTeasers";
import { NewsletterBanner } from "../components/NewsletterBanner";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { LinkedinPosts } from "../components/LinkedinPosts";
import { Image } from "../components/Slices/Image";

const Community = ({ data }) => {
  const page = data.prismicCommunity;

  const { lang, type, url } = page || {};
  const title = page.data.title.text;
  const alternateLanguages = page.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    title,
  };

  return (
    <Layout activeDocMeta={activeDoc} showSearch={false}>
      <div className="community-page">
        <Hero
          title={page.data.title.text}
          image={page.data.image}
          background="-light"
          imageMobile={'-siderea-top'}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: filterHtml(page.data.intro.html),
            }}
          ></div>
          <div className="c-buttons">
            <GatsbyLink
              link={page.data.cta_link}
              label={page.data.cta_label}
              className="c-btn -primary -medium u-mt3"
            />
          </div>
        </Hero>
        <LinkedinPosts intro={page.data.linkedin_intro} posts={page.data.linkedin_posts}/>
        <div className="o-stretch-wrapper c-community-quote__wrapper">
          <div className="o-wrapper u-mt0">
            <div className="c-community-quote quote-community">
              {page.data.quote_image.localFile && (
                <div className="c-community-quote__image">
                  <Image key={page.data.quote_image.id} image={page.data.quote_image} />
                </div>
              )}
              <div className="c-community-quote__text">
                <div
                  className="c-community-quote__quote-text"
                  dangerouslySetInnerHTML={{ __html: filterHtml(page.data.quote_text.html) }}
                />
                {page.data.quote_author && <div className="c-community-quote__quote-name">{page.data.quote_author}</div>}
              </div>
            </div>
          </div>
        </div>
        <EventTeasers title={page.data.events_future_title.text} time="Coming" />
        <EventTeasers title={page.data.events_past_title.text} time='Past' />
        <NewsletterBanner title={page.data.newsletter_title.text} description={page.data.newsletter_description.html} cta_link={page.data.newsletter_cta_link} cta_label={page.data.newsletter_cta_label} />
      </div>
    </Layout>
  );
};

Community.propTypes = {
  data: PropTypes.object,
};

export default withPrismicPreview(Community);

export const query = graphql`
  query ($id: String, $lang: String!, $locale: String) {
    prismicCommunity(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      id
      type
      data {
        title {
          text
        }
        intro {
          html
        }
        cta_link {
          id
          url
          target
          type
          link_type
        }
        cta_label
        image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 900
                layout: CONSTRAINED
                placeholder: NONE
              )
            }
            publicURL
          }
          thumbnails {
            mobile {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1230
                    layout: CONSTRAINED
                    placeholder: NONE
                  )
                }
                publicURL
              }
            }
          }
        }
        linkedin_intro {
          linkedin_description {
            html
          }
        }
        linkedin_posts {
          linkedin_post_author
          linkedin_post_description {
            html
          }
          linkedin_post_image {
            alt
            url
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          linkedin_post_link {
            id
            url
            target
            type
            link_type
          }
        }
        quote_author
        quote_image {
          alt
          url
          localFile {
            childImageSharp {
              gatsbyImageData (
                aspectRatio: 1.067
              )
            }
            publicURL
          }
        }
        quote_text {
          html
        }
        events_future_title {
          text
        }
        events_past_title {
          text
        }
        newsletter_title {
          text
        }
        newsletter_description {
          html
        }
        newsletter_cta_link {
          id
          url
          target
          type
          link_type
        }
        newsletter_cta_label
      }
      type
      url
      alternate_languages {
        document {
          ... on PrismicCommunity {
            url
          }
        }
        lang
      }
      lang
    }
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $locale } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
