import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { PropTypes } from 'prop-types'


export const StepCounter = ({ step, setStep }) => {
	const { t } = useTranslation();
	return (
    <>
      {([1,2,3].includes(step)) && (
        <>
          <h5>{t("Beantwoord 3 vragen en we helpen je op weg.")} </h5>
          <div className="step-counter">
            <button className={step >= 1 ? 'active' : ''} onClick={() => setStep(1)}><span>1</span></button>
            <div className={`line${step >= 2 ? ' active' : ''}`}></div>
            <button className={step >= 2 ? 'active' : ''} onClick={() => setStep(2)}><span>2</span></button>
            <div className={`line${step >= 3 ? ' active' : ''}`}></div>
            <button className={step >= 3 ? 'active' : ''} onClick={() => setStep(3)}><span>3</span></button>
          </div>
        </>
      )}
    </>
    )
}

StepCounter.propTypes = {
	step: PropTypes.number,
  setStep: PropTypes.func
}