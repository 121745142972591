import * as React from "react";
import { SearchWrapper } from "./SearchWrapper";
import { PropTypes } from "prop-types";
import { Image } from "./Slices/Image";
import { Breadcrumb } from "./Breadcrumb";

export const Hero = ({
  children,
  title,
  search = false,
  showImage = true,
  imageMobile = '',
  breadcrumbs,
  image,
  background = "-home",
}) => {

  const hero_image = image || {
    url: "/man_vrouw_boeken.png",
    alt: "man en vrouw op boeken",
  };

  return (
    <div className={`o-stretch-wrapper c-page-header ${background}`}>
      <div className="o-wrapper u-mt0 u-mb0 u-mb0@screen">
        <div className={`o-2-col -sidearea u-rowgap0 ${imageMobile}`}>
          <div className="c-page-header__textblock u-pb0">
            {breadcrumbs && 
              <div className="u-mb3">
                <Breadcrumb color={["-home", "-event", "-webinar"].includes(background) ? "white" : ""} activeDocMeta={breadcrumbs} />
              </div>
            }
            {title && <h1 className="h1">{title}</h1>}
            {children}
            {search && <SearchWrapper source="hero" />}
          </div>
          {showImage && (
            <div className={`c-page-header__sidearea is-sidearea ${imageMobile !== '' && "showImageOnMobile"}`}>
              <Image image={hero_image} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string,
  search: PropTypes.bool,
  showImage: PropTypes.bool,
  imageMobile: PropTypes.string,
  image: PropTypes.object,
  background: PropTypes.string,
  breadcrumbs: PropTypes.object
};
