import * as React from "react";
import { PropTypes } from "prop-types";
import { useTranslation } from "gatsby-plugin-react-i18next";

export const Filter = (props) => {
  const { items, onFiltersChange, tagInfo } = props;
  const { t } = useTranslation();
  const [subthemes, setSubthemes] = React.useState([]);
  const [types, setTypes] = React.useState([]);
  const [dropdownTypesOpen, setDropdownTypesOpen] = React.useState(false);
  const [activeSubtheme, setactiveSubtheme] = React.useState("");

  const dropdownRef = React.useRef(null);
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownTypesOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  React.useEffect(() => {
    let new_subthemes = [];
    let new_types = [];
    items.forEach((element) => {
      if (element.data.subtheme) new_subthemes.push(element.data.subtheme);
      if (element.type === "learning_overview") new_types.push("Interactive");
      else if (element.type === "level_2" && element.data.blog) new_types.push('Blog')
      else if (
        element.data.types?.length !== 0 &&
        element.data.types[0].tag_type
      )
        new_types.push(element.data.types[0].tag_type);
    });
    setSubthemes([...new Set(new_subthemes)]);
    setTypes([...new Set(new_types)]);
  }, [items]);

  const updateTypes = ({ name, value }) => {
    onFiltersChange({ filter: "type", name, value });
  };

  const updateSubtheme = (e, subtheme) => {
    e.preventDefault();
    onFiltersChange({ filter: "subtheme", activeSubtheme: subtheme });
    setactiveSubtheme(subtheme);
  };

  const showAllSubthemes = (e) => {
    e.preventDefault();
    setactiveSubtheme([]);
    onFiltersChange({ filter: "subtheme", activeSubtheme: "" });
  };

  return (
    <form className="form-block basic-form filter-block -theme">
      {subthemes.length !== 0 && (
        <div className="form-field field-buttons">
          <button
            onClick={showAllSubthemes}
            className="field-button c-btn -small -ghost"
          >
            {t("Alles")}
          </button>
          {subthemes.map((subtheme) => (
            <button
              onClick={(e) => updateSubtheme(e, subtheme)}
              className={`field-button c-btn -small ${
                activeSubtheme === subtheme ? "-primary" : "-ghost"
              }`}
              key={subtheme}
            >
              {subtheme}
            </button>
          ))}
        </div>
      )}
      <div className="form-field field-dropdown field-types" ref={dropdownRef}>
        <div
          className="form-field-icon"
          onClick={() => setDropdownTypesOpen(!dropdownTypesOpen)}
          onKeyDown={() => setDropdownTypesOpen(!dropdownTypesOpen)}
          role="button"
          tabIndex={0}
        >
          <div className="field-button">{t("Type")}</div>
          <span
            className={
              "field-icon icon-arrow-" + (dropdownTypesOpen ? "up" : "down")
            }
          />
        </div>
        <div className={`form-field-list ${dropdownTypesOpen ? "open" : ""}`}>
          {types.map((type) => (
            <div className="checkbox" key={"provincie-" + type}>
              <input
                type="checkbox"
                name={type}
                id={type}
                onInput={(e) => {
                  updateTypes({ name: type, value: e.target.checked });
                }}
              />
              <label htmlFor={type}>{tagInfo[type].label}</label>
            </div>
          ))}
        </div>
      </div>
    </form>
  );
};

Filter.propTypes = {
  items: PropTypes.array,
  onFiltersChange: PropTypes.func,
  tagInfo: PropTypes.object,
};
