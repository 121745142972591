
export const generateUrl = (lang, url) => {

	const languages = {
		'nl-be': '',
		'fr-be': 'fr',
		'en-eu': 'en'
	}

	let fullUrl = '';
	if (languages[lang]) {
		fullUrl += '/' + languages[lang];
	}
	if (url) {
		fullUrl += '/' + url;
	}

	return fullUrl || '/';
}
