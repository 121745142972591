module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-87LELCHCH0","cookieName":"gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-WJNN6SV","cookieName":"gdpr-google-analytics","dataLayerName":"dataLayer"},"googleTagManagerIO":{"trackingId":"GTM-MB7LQ6C","cookieName":"gdpr-google-analytics","dataLayerName":"dataLayeriO"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["nl"],"defaultLanguage":"nl","siteUrl":"http://localhost:8000","i18nextOptions":{"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":"."},"pages":[{"matchPath":"/:lang","getLanguageFromPath":true},{"matchPath":"/:lang/","getLanguageFromPath":true},{"matchPath":"/:lang/:theme","getLanguageFromPath":true},{"matchPath":"/:lang/:theme/:level2","getLanguageFromPath":true},{"matchPath":"/:lang/:theme/:level2/:level3","getLanguageFromPath":true},{"matchPath":"/:lang/community/:uid","getLanguageFromPath":true},{"matchPath":"/:lang/case-study/:case-study","getLanguageFromPath":true},{"matchPath":"/:lang/:downloads","getLanguageFromPath":true},{"matchPath":"/:lang/:downloads/:level2","getLanguageFromPath":true},{"matchPath":"/:lang/:downloads/:level2/:level3","getLanguageFromPath":true},{"matchPath":"/:lang/:theme/:learning-overview","getLanguageFromPath":true},{"matchPath":"/:lang/:theme/:learning-overview/:learning-detail","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"vdab-infopunt-e-leren","accessToken":"MC5ZYVQ5dUJNQUFDSUF0MWsw.77-977-977-977-977-977-977-977-9MXU_77-977-9QWNDEu-_ve-_vULvv70SFDEKFu-_ve-_vU3vv70TOg","lang":"nl-be","promptForAccessToken":true,"apiEndpoint":"https://vdab-infopunt-e-leren.cdn.prismic.io/api/v2","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icons":[{"src":"/favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
