import * as React from "react";
import { AppContext } from "../context/AppContext";
import { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

export const ProfileSwitcher = () => {
  const context = React.useContext(AppContext);
  const [open, setOpen] = useState(false);

  const queryData = useStaticQuery(graphql`
    query VisitorProfilesQuery($lang: String) {
      allPrismicVisitorProfile(
        sort: { fields: data___weight, order: ASC }
        filter: { lang: { eq: $lang } }
      ) {
        nodes {
          _previewable
          data {
            name
          }
          uid
        }
      }
    }
  `);
  const { data } = useMergePrismicPreviewData(queryData)

  const toggleDetails = () => {
    setOpen(!open);
  };
  const handleClick = (profile) => {
    setOpen(false);
    context.setLevel(profile);
  };

  const getProfilename = () => {
    let name = "";
    name = queryData.allPrismicVisitorProfile.nodes.filter((profile) => (profile.uid === context.level)).map((profile) => (profile.data.name));
    return name;
  };

  return (
    <section
      className={`profile-switcher ${
        open ? "profile-switcher--open" : "profile-switcher--closed"
      }`}
    >
      <header role="presentation" onClick={() => toggleDetails()} onKeyDown={() => toggleDetails()} className={`profile ${context.level}`}>
        {getProfilename()}
      </header>
      <ul>
        {data.allPrismicVisitorProfile.nodes.map((profile) => {
          return profile.uid !== context.level ? (
            <li key={"profile-" + profile.uid}>
              <button
                className={`profile ${profile.uid}`}
                onClick={() => handleClick(profile.uid)}
              >
                {profile.data.name}
              </button>
            </li>
          ) : null;
        })}
      </ul>
    </section>
  );
};
