import * as React from "react";
import { PropTypes } from "prop-types";
import { Link } from "gatsby";
import { Image } from "./Slices/Image";

export const NavigationBlock = ({ title, items, image}) => {

  return (
    <div className="o-wrapper c-navigation">
      <div className="o-1-col c-navigation-header">
        <h2 className='h1'>{title}</h2>
      </div>
      <div className="o-3-col c-navigation-block -home">
        {items.map((value) => (
          <article className="c-navigation-block__item" key={value.id}>
            <h3 className="c-navigation-block__title">
              <Link className="o-routing-link action-go-after" to={value.url}>{value.data.title.text}</Link>
            </h3>
            <div className="c-navigation-block__content">
              <p>{value.data.abstract.text}</p>
            </div>
          </article>
        ))}
        {image && <Image className="c-navigation-block-image" image={image} />}
      </div>
    </div>
  );
};

NavigationBlock.propTypes = {
  title: PropTypes.string,
	items: PropTypes.array,
  image: PropTypes.object
};
