import HomePage from "../templates/homepage";
import Theme from "../templates/theme";
import Editorial from "../templates/editorial";
import Level2 from "../templates/level2";
import Level3Faq from "../templates/level3_faq"
import Level3Step from "../templates/level3_step"
import Disclaimer from "../templates/disclaimer"
import Privacy from "../templates/privacy"
import CookiePolicy from "../templates/cookie_policy"
import CaseStudies from "../templates/case_studies"
import CaseStudy from "../templates/case_study"
import Downloads from '../templates/downloads'
import LearningOverview from "../templates/learning-overview"
import LearningDetail from "../templates/learning-detail"
import Event from "../templates/event"
import Community from "../templates/community"
import Blog from "../templates/blog"

export const typeToTemplateList = {
  home: HomePage,
  theme: Theme,
  editorial: Editorial,
  level_2: Level2,
  level_3_faq: Level3Faq,
  level_3_step: Level3Step,
  disclaimer: Disclaimer,
  privacy: Privacy,
  cookie_policy: CookiePolicy,
  cases_overview: CaseStudies,
  case_study: CaseStudy,
  downloads: Downloads,
  learning_overview: LearningOverview,
  learning_detail: LearningDetail,
  event: Event,
  community: Community,
  blog: Blog,
}