import * as React from 'react'
import { Textblock } from './Slices/Textblock';
import { Quote } from './Slices/Quote';
import { Download } from './Slices/Download';
import { Image } from './Slices/Image';
import { VideoList } from './Slices/VideoList';
import { PodcastList } from './Slices/PodcastList';
import { FileColumn } from './Slices/FileColumn';
import { TableRow } from './Slices/TableRow';
import { Carousel } from './Slices/Carousel';
import { CTABlock } from './Slices/CTABlock';
import { EventTeasers } from './EventTeasers';

export const SliceZone = ({ slices }) => {
	return slices.map((value) => {
		switch (value.slice_type) {
			case 'textblock':
				return <Textblock key={value.id} title={value.primary.title1.text} text={value.primary.text.html} />
			case 'quote':
				return <Quote key={value.id} text={value.primary.quote.html} author={value.primary.author} image={value.primary.image} />
			case 'download':
				return <Download key={value.id} target='_blank' data={value.primary} />
			case 'image':
				return <Image key={value.id} image={value.primary.image} className="c-image-block" />
			case 'videos':
				return <VideoList key={value.id} value={value}/>
			case 'podcasts':
				return <PodcastList key={value.id} value={value}/>
			case 'files':
				return <FileColumn key={value.id} value={value}/>
			case 'table_row':
				return <TableRow key={value.id} value={value}/>
			case 'carousel':
				return <Carousel key={value.id} items={value.items}/>
			case 'cta_block':
				return <CTABlock key={value.id} data={value.primary} items={value.items} />
			case 'event_list':
				return <EventTeasers key={value.id} title={value.primary.heading} time={value.primary.time} type={value.primary.type} display='small'/> 
			default:
				return <><p>{JSON.stringify(value)}</p></>
		}
	})
}
