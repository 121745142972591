import * as React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, Link } from "gatsby";
import { Layout } from "../components/Layout";
import { SliceZone } from "../components/SliceZone";
import { PropTypes } from "prop-types";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const Level3Faq = ({ data }) => {
	const {t} = useTranslation();
  if (!data) return null;
  let page = data.prismicLevel3Faq;
  const { lang, type, url } = page || {};

  const alternateLanguages = page.alternate_languages || [];
  const parents = [{
    url: page.data.parent.document.data.parent.url,
    label: page.data.parent.document.data.parent.document.data.title.text
  },{
    url: page.data.parent.url,
    label: page.data.parent.document.data.title.text
  }]
  const title = page.data.title.text;
  const desc = page.data.meta_description;
  const activeDoc = { lang, type, url, alternateLanguages, title, desc, parents };

  return (
    <Layout activeDocMeta={activeDoc}>
      <div className="o-wrapper">
        <div className="o-1-col">
          <h1 className="c-intro-block__title">{page.data.title.text}</h1>
          <div className="c-divider u-mb0" />
        </div>
      </div>
      {page.data.body.length > 0 && (
        <div className="o-wrapper o-2-col -sidearea">
          <div>
            <SliceZone slices={page.data.body} />
          </div>
        </div>
      )}
      {(page.data.related_questions.length > 0 && page.data.related_questions[0].value?.question?.document) && (
        <div className="o-wrapper related related-questions">
          <h2>{t('Related questions')}</h2>
          <ul>
            {page.data.related_questions.map((value) => {
              return (
                <li key={value.question.url}>
                  <Link to={value.question.url}>
                    {value.question.document.data.title.text}
                  </Link>
                </li>
							)
            })}
          </ul>
        </div>
      )}
    </Layout>
  );
};

Level3Faq.propTypes = {
  data: PropTypes.object,
};

export default withPrismicPreview(Level3Faq);

export const query = graphql`
  query ($id: String, $lang: String!, $locale: String) {
    prismicLevel3Faq(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      id
      type
      data {
        title {
          text
        }
        meta_description
        parent {
          url
          document {
            ... on PrismicLevel2 {
              id
              data {
                title {
                  text
                }
                parent {
                  url
                  document {
                    ... on PrismicTheme {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicDownloads {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        related_questions {
          question {
            document {
              ... on PrismicLevel3Faq {
                id
                data {
                  title {
                    text
                  }
                }
              }
            }
            url
          }
        }
        body {
          ... on PrismicLevel3FaqDataBodyImage {
            id
            primary {
              image {
                url
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                  publicURL
                }
                thumbnails {
                  mobile {
                    url
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                      }
                      publicURL
                    }
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicLevel3FaqDataBodyDownload {
            id
            slice_type
            primary {
              file {
                url
                size
                localFile {
                  extension
                  publicURL
                  size
                }
              }

              label
            }
          }
          ... on PrismicLevel3FaqDataBodyQuote {
            id
            primary {
              author
              image {
                alt
                url
                localFile {
                  publicURL
                }
              }
              quote {
                html
              }
            }
            slice_type
          }
          ... on PrismicLevel3FaqDataBodyTextblock {
            id
            primary {
              text {
                html
              }
              title1 {
                text
              }
            }
            slice_type
          }
        }
      }
      url
      alternate_languages {
        document {
          ... on PrismicLevel3Faq {
            url
          }
        }
        lang
      }
      lang
    }
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $locale } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
