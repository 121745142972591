import React from "react";
import Cookies from "universal-cookie";
import { PropTypes } from "prop-types";
import { initializeAndTrackGoogleTagManager, initializeAndTrackGoogleAnalytics} from 'gatsby-plugin-gdpr-cookies/services'
import { initializeAndTrackGoogleTagManagerOther } from '../utils/addGTM'

const consentVersion = "1";

export const AppContext = React.createContext({});

function isBrowser() {
  return typeof window !== "undefined";
}

export const AppProvider = ({ children, location }) => {
  const getShowProfileInitialState = () => {
    if (isBrowser()) {
      const lsShowProfile = JSON.parse(localStorage.getItem("show-profile-section"));
      return lsShowProfile || false;
    } else {
      return false;
    }
  };

  const getHideProfileModalInitialState = () => {
    const cookies = new Cookies();
    const cShowProfile = cookies.get("hide-profile-modal") === "0"
    return cShowProfile || true;
  };

  const getLevelInitialState = () => {
    if (isBrowser()) {
      const lsLevel = JSON.parse(localStorage.getItem("level"));
      return lsLevel || "debutant";
    } else {
      return "debutant";
    }
  };

  const getTypesInitialState = () => {
    if (isBrowser()) {
      const lsTypes = JSON.parse(localStorage.getItem("types"));
      return lsTypes || [];
    } else {
      return [];
    }
  };

  const getThemesInitialState = () => {
    if (isBrowser()) {
      const lsThemes = JSON.parse(localStorage.getItem("themes"));
      return lsThemes || [];
    } else {
      return [];
    }
  };

  const getCookiesInitialState = () => {
    const cookies = new Cookies();
    return {
      analytics: cookies.get("gdpr-google-analytics") === "true",
      personalisation: cookies.get("gdpr-personalisation") === "true",
    };
  };

  const getCookieModalState = () => {
    return (
      !isBrowser() ||
      (window.localStorage.getItem("consentCookieVersion") &&
        JSON.parse(window.localStorage.getItem("consentCookieVersion")) ===
          consentVersion)
    );
  };

  const [showProfile, setShowProfile] = React.useState(getShowProfileInitialState);
  const [hideProfileModal, setHideProfileModal] = React.useState(getHideProfileModalInitialState);
  const [level, setLevel] = React.useState(getLevelInitialState);
  const [types, setTypes] = React.useState(getTypesInitialState);
  const [themes, setThemes] = React.useState(getThemesInitialState);
  const [cookiesState, setCookiesState] = React.useState(getCookiesInitialState);

  const handleShowProfile = React.useCallback((profile) => {
    localStorage.setItem("show-profile-section", JSON.stringify(profile));
    setShowProfile(profile);
  }, []);

  const handleHideProfileModal = React.useCallback((profile) => {
    const cookies = new Cookies();
    const value = profile ? 0 : 1
    cookies.set('hide-profile-modal', value, { path: '/', sameSite: 'Strict', maxAge: 60 * 60 * 24 * 365 });
    setHideProfileModal(profile);
  }, []);

  const handleLevel = React.useCallback((level) => {
    localStorage.setItem("level", JSON.stringify(level));
    setLevel(level);
  }, []);

  const handleTypes = React.useCallback((types) => {
    localStorage.setItem("types", JSON.stringify(types));
    setTypes(types);
  }, []);

  const handleThemes = React.useCallback((themes) => {
    localStorage.setItem("themes", JSON.stringify(themes));
    setThemes(themes);
  }, []);

  const handleCookies = React.useCallback((data) => {
    const cookies = new Cookies();
    cookies.set('gdpr-google-analytics', data.analytics, { path: '/', sameSite: 'Strict', maxAge: 60 * 60 * 24 * 365 });
    cookies.set('gdpr-personalisation', data.personalisation, { path: '/', sameSite: 'Strict', maxAge: 60 * 60 * 24 * 365 });
    if (window.gatsbyPluginGDPRCookiesOptions && data.analytics) {
      initializeAndTrackGoogleTagManagerOther(window.gatsbyPluginGDPRCookiesOptions.googleTagManagerIO, location);
      initializeAndTrackGoogleTagManager(window.gatsbyPluginGDPRCookiesOptions.googleTagManager, location);
      initializeAndTrackGoogleAnalytics(window.gatsbyPluginGDPRCookiesOptions.googleAnalytics, location);
    }
    window.localStorage.setItem('consentCookieVersion', JSON.stringify(consentVersion));

    setCookiesState(cookies);
  }, [location]);

  const data = React.useMemo(
    () => ({
      showProfile: showProfile,
      hideProfileModal: hideProfileModal,
      level: level,
      types: types,
      themes: themes,
      setShowProfile: handleShowProfile,
      setHideProfileModal: handleHideProfileModal,
      setLevel: handleLevel,
      setTypes: handleTypes,
      setThemes: handleThemes,
      cookiesState: cookiesState,
      setCookiesState: handleCookies,
      getCookieModalState: getCookieModalState
    }),
    [
      showProfile,
      hideProfileModal,
      level,
      types,
      themes,
      handleShowProfile,
      handleHideProfileModal,
      handleLevel,
      handleTypes,
      handleThemes,
      cookiesState,
      handleCookies,
    ]
  );

  return <AppContext.Provider value={data}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};