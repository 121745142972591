import * as React from 'react'
import slugify from 'react-slugify';
import { PropTypes} from 'prop-types';
import { filterHtml } from '../../utils/filterHtml';

export const Textblock = ({ title, text }) => {
	return (
		<div className="c-content-block" id={title ? slugify(title) : undefined}>
			{title.length > 0 && <h2>{title}</h2>}
			<div dangerouslySetInnerHTML={{ __html: filterHtml(text) }} />
		</div>
	)
}

Textblock.propTypes = {
	text: PropTypes.string,
	title: PropTypes.string
}