import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Image } from "../components/Slices/Image";
import { filterHtml } from "../utils/filterHtml";
import { PropTypes } from "prop-types";

export const Welcome = ({ page }) => {
  const { t } = useTranslation();

  return (
    <div className="main" id={t("welcome")}>
      {page.data.image.url && (
        <>
          <h1 className="c-intro-block__title">{page.data.title1.text}</h1>
          {page.data.title?.text && <h2>{page.data.title.text}</h2>}
          <div className="o-2-col">
            <Image
              className="c-learning-welcome__image"
              key={page.data.image.id}
              image={page.data.image}
            />
            <div className="u-display-flex u-flex-direction-column u-justify-content-between">
              <div
                dangerouslySetInnerHTML={{
                  __html: filterHtml(page.data.description.html),
                }}
              ></div>
            </div>
          </div>
        </>
      )}
      {!page.data.image.url && (
        <div className="">
					<h1 className="c-intro-block__title">{page.data.title.text}</h1>
					{page.data?.welcome_video?.html && 
						<div className="c-video__video u-mt2" dangerouslySetInnerHTML={{__html: filterHtml(page.data.welcome_video?.html)}}></div>
					}
					<h2 className="u-mt1">{page.data.title1.text}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: filterHtml(page.data.description.html),
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

Welcome.propTypes = {
  page: PropTypes.object,
};
