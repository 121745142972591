import * as React from 'react'
import { Video } from './Video'
import { useTranslation } from "gatsby-plugin-react-i18next";
import { PropTypes } from 'prop-types'
import { filterHtml } from '../../utils/filterHtml';

export const VideoList = ({value}) => {
  const { t } = useTranslation();
	const [amount, setamount] = React.useState(1)
	const offset = 3

	return (
			<div className="c-videolist">
				<div className="c-videolist__title"><h2>{value.primary?.title?.text}</h2></div>
				<div className="c-videolist__description" dangerouslySetInnerHTML={{__html: filterHtml(value.primary?.description?.html)}}></div>
				<div className="c-videolist__highlight">
					<Video key={value.id + value.items[0].video.id} item={value.items[0]}/>
				</div>
				<div className="c-videolist__list o-3-col">
					{value.items?.slice(1, amount + offset).map((item) => (
						<Video key={value.id + item.video.id} item={item}/>
					))}
				</div>
				{value.items?.length > (amount + offset) && (
					<div className="c-videolist__button">
						<button className='c-btn -full-width@mobile' onClick={(e) => {e.preventDefault(); setamount(amount + offset)}}>{t('Load more videos')}</button>
					</div>
				)}
			</div>
	)
}

VideoList.propTypes = {
	value: PropTypes.object
}