import * as React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { PropTypes } from "prop-types";
import { Image } from "../components/Slices/Image";
import { filterHtml } from "../utils/filterHtml";
import { GatsbyLink } from "../components/Slices/GatsbyLink";
import { useTranslation} from 'gatsby-plugin-react-i18next';
import { SliceZone } from "../components/SliceZone";
import { Hero } from "../components/Hero";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const Event = ({ data }) => {
	const {t} = useTranslation();
  const page = data.prismicEvent;
  let showPopup = false;
  if (typeof window !== 'undefined' && window?.location?.hash) {
    showPopup = window.location.hash
  }
  
  const parents = [
    {
      label: data.allPrismicCommunity.nodes?.[0]?.data.title.text,
      url: data.allPrismicCommunity.nodes?.[0]?.url
    },
  ];
  const { lang, type, url } = page || {};
  const title = page.data.title.text;
  const alternateLanguages = page.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    title,
    parents
  };

  const currentDate = new Date();
  const eventDate = new Date(page.data.date)
  const eventPast = currentDate > eventDate
  const isWebinar = page.data.type === "Webinar";
  const hasAlternateHeroVisual = page.data.alternate_hero_visual;

  let image;
  let background;

  if (isWebinar) {
    if (hasAlternateHeroVisual) {
      image = { url: "/webinar-img-alt.svg", alt: "webinar" };
      background = "-webinar alt";
    } else {
      image = { url: "/webinar-img.svg", alt: "webinar" };
      background = "-webinar";
    }
  } else {
    image = undefined;
    background = "-event";
  }

  return (
		<Layout activeDocMeta={activeDoc} hideBreadcrumb>
      <div className={`event-page ${page.data.type}`}>
        <Hero
          title={page.data.title.text}
          showImage={page.data.type === "Webinar"}
          background={background}
          image={image}
          breadcrumbs={activeDoc}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: filterHtml(page.data.intro.html),
            }}
          ></div>
        </Hero>
        <div className={`o-wrapper o-2-col -sidearea`}>
          <div className="main">
            <SliceZone slices={eventPast && page.data.body.length ? page.data.body : page.data.body1} />
          </div>
          {!eventPast && 
            <div className="c-sidearea is-sidearea">
              <div className="c-event-block c-sticky-block">
                <div className="c-event-block__container c-sticky-block__container">
                  {page.data.visual &&<Image className="c-event-block__visual" image={page.data.visual} />}
                  <div className="c-event-block__content">
                    <div className={`c-event-teaser__type type${page.data.type}`}>
                      <span className={`icon-${page.data.type === "Event" ? 'date' : 'computer'}`}>{page.data.type}</span>
                    </div>
                    <h4 className="c-event-block__title">{page.data.sidebar_title.text}</h4>
                    <div className="c-event-block__description" dangerouslySetInnerHTML={{__html: filterHtml(page.data.sidebar_body.html)}}/>
                    {page.data.button_link?.url && <GatsbyLink className={`c-btn -primary -full-width ${page.data.type}`} link={page.data.button_link} label={page.data.button_label} />}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      
      {page.data.popup.map((popup) => (
        /* eslint eqeqeq: 0 */
        <div className={`c-modal__cookie-overlay c-modal__event-popup ${ showPopup == "#"+popup.popup_id ? "" : 'u-display-none'}`} key={"popup-"+popup.popup_id}>
          <div className="c-modal -medium ">
            <div className="c-modal__header">
              <div className="c-modal__title">
                {popup.popup_image.url && (
                  <Image className="c-modal__image" image={popup.popup_image} />
                )}
                <h2>{popup.popup_title.text}</h2>
              </div>
              <div className="c-modal__close">
                <a className="icon-close" href="#close">
                  <span>{t("Close")}</span>
                </a>
              </div>
            </div>
            <div className="c-modal__body">
              <div className="c-modal__description" dangerouslySetInnerHTML={{__html: filterHtml(popup.popup_description.html)}}/>
            </div>
          </div>
        </div>
      ))}
    </Layout>
  );
};

Event.propTypes = {
  data: PropTypes.object,
};

export default withPrismicPreview(Event);

export const query = graphql`
  query ($id: String, $lang: String!, $locale: String) {
    allPrismicCommunity {
      nodes {
        _previewable
        url
        data {
          title {
            text
          }
        }
      }
    }
    prismicEvent(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      id
      type
      data {
        title {
          text
        }
        date
        intro {
          html
        }
        type
        body1 {
          ... on PrismicEventDataBody1TableRow {
            id
            items {
              person_category
              person_image {
                url
                alt
                localFile {
                  publicURL
                }
              }
              person_title {
                text
              }
              person_link {
                url
              }
            }
            slice_type
            primary {
              download {
                url
                size
                localFile {
                  extension
                  publicURL
                  size
                }
                raw
                id
              }
              display_open
              table_row_description {
                html
              }
              table_row_title {
                text
              }
              tag
              time
            }
          }
          ... on PrismicEventDataBody1Textblock {
            id
            slice_type
            primary {
              text {
                html
              }
              title1 {
                text
              }
            }
          }
          ... on PrismicEventDataBody1Carousel {
            id
            slice_type
            items {
              image {
                url
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 1150
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          ... on PrismicEventDataBody1Download {
            id
            slice_type
            primary {
              display_as_button
              file {
                url
                size
                localFile {
                  extension
                  publicURL
                  size
                }
              }
              label
            }
          }
        }
        body {
          ... on PrismicEventDataBodyCarousel {
            id
            slice_type
            items {
              image {
                url
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
          ... on PrismicEventDataBodyTableRow {
            id
            items {
              person_category
              person_image {
                url
                alt
                localFile {
                  publicURL
                }
              }
              person_title {
                text
              }
            }
            slice_type
            primary {
              download {
                url
                size
                localFile {
                  extension
                  publicURL
                  size
                }
                raw
                id
              }
              download_label
              display_open
              table_row_description {
                html
              }
              table_row_title {
                text
              }
              tag
              time
            }
          }
          ... on PrismicEventDataBodyTextblock {
            id
            slice_type
            primary {
              text {
                html
              }
              title1 {
                text
              }
            }
          }
          ... on PrismicEventDataBodyCtaBlock {
            id
            items {
              cta_label
              cta_link {
                id
                url
                localFile {
                  publicURL
                }
                link_type
                target
              }
            }
            primary {
              description {
                html
              }
              image {
                url
                alt
                localFile {
                  publicURL
                }
              }
              title1 {
                text
              }
            }
            slice_type
          }
          ... on PrismicEventDataBodyDownload {
            id
            slice_type
            primary {
              display_as_button
              file {
                url
                size
                localFile {
                  extension
                  publicURL
                  size
                }
              }
              label
            }
          }
        }
        button_label
        button_link {
          url
          localFile {
            publicURL
          }
          link_type
          target
        }
        sidebar_body {
          html
        }
        sidebar_title {
          text
        }
        visual {
          url
          alt
          localFile {
            publicURL
          }
        }
        popup {
          popup_id
          popup_description {
            html
          }
          popup_image {
            alt
            url
            localFile {
              publicURL
            }
          }
          popup_title {
            text
          }
        }
        alternate_hero_visual
      }
      type
      url
      alternate_languages {
        document {
          ... on PrismicLearningDetail {
            url
          }
        }
        lang
      }
      lang
    }
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $locale } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
