import React, { useState, useEffect } from "react";
import ExitIntentFn from "../utils/exitIntent";
import Cookies from "universal-cookie";

export const ExitIntent = () => {
  const cookies = new Cookies();
  const [showPopup, setShowPopup] = useState(false);
  const popupClickedAway = cookies.get("exit_intent_seen");

  useEffect(() => {
    if (showPopup) {
      cookies.set("exit_intent_seen", true, {
        path: "/",
        sameSite: "Strict",
        maxAge: 60 * 60 * 24 * 14,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopup]);

  useEffect(() => {
    const removeExitIntent = ExitIntentFn({
      threshold: 30,
      eventThrottle: 100,
      onExitIntent: () => {
        setShowPopup(true);
      },
    });
    return () => {
      removeExitIntent();
    };
  });

  if (!showPopup || popupClickedAway) return null;

  return (
    <div className="c-modal__cookie-overlay" id="exitintent">
      <div className="c-modal c-modal__profile -medium -full-screen@tablet">
        <div className="c-modal__header">
          <div className="c-modal__close">
            <button className="icon-close" onClick={() => setShowPopup(false)}>
              <span className="hide">Close</span>
            </button>
          </div>
        </div>
        <div className="c-modal__body">
          <h2 className="c-modal__title">Voordat je vertrekt…</h2>
          <p>
            Volg ons op LinkedIn en blijf op de hoogte van alle aankomende
            evenementen.
          </p>
          <a
            href="https://www.linkedin.com/groups/12916020/"
            target="_blank"
            rel="noreferrer"
            className="c-btn -primary -medium -full-width@mobile"
          >
            Word deel van onze community!
          </a>
          <img id="intro" src="/profiles/rafiki.png" alt="rafiki" />
        </div>
      </div>
    </div>
  );
};
