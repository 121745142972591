import * as React from "react";
import { Image } from "./Image";
import { PropTypes } from "prop-types";
import { filterHtml } from "../../utils/filterHtml";

export const Quote = ({ author, text, image }) => {
  return (
    <div className="c-quoteblock bg-grey-100">
      {image.localFile && (
        <div className="c-quoteblock__image">
          <Image key={image.id} image={image} />
        </div>
      )}
      <div className="c-quoteblock__text">
        <div
          className="c-quoteblock__quote-text"
          dangerouslySetInnerHTML={{ __html: filterHtml(text) }}
        />
        {author && <div className="c-quoteblock__quote-name">{author}</div>}
      </div>
    </div>
  );
};

Quote.propTypes = {
  image: PropTypes.object,
  author: PropTypes.string,
  text: PropTypes.string,
};
