import * as React from 'react'

export const StickyButton = () => {
  const anchor = 'content'
  return (
    <div className="c-sticky-button">
      <a href={`#${anchor}`}>
        <i className='icon-arrow-up'></i>
        <span>Back to top</span>
      </a>
    </div>
  )
};
