export const filterContent = (content, context) => {
  let filteredContent = content.filter((value) => (value.data.tag_profiles?.filter((tagProfile) => tagProfile.profile.uid === context.level).length)).map((value) => {
    let importance = 0;
    if (value.data.types?.filter((type) => context.types.includes(type.tag_type)).length) {
      importance++;
    }
    if (context.themes.includes(value.data.parent?.uid)) {
      importance++;
    }
    return { importance, value }
  });

  // sort by importance
  filteredContent
    .sort(function (a, b) {
      if (a.importance > b.importance) return -1;
      if (a.importance < b.importance) return 1;
      return 0;
    })
  return filteredContent
    .map((item) => {
      return item.value;
    });
};

export const filterLearningOverviewContent = (content, context) => {
  let levels = {
    "debutant": "Didactiek",
    "medior": "Change Management",
    "expert": "Change Management"
  };
  const filteredContent = content.filter((value) => {
    let accessible = false
    for (const chapter of value.data.body[0].items) {
      if (chapter.accessible) {
        accessible = true;
        break;
      }
    }
    return (accessible && (context === undefined || levels[context.level].includes(value.data.title.text)))
  })
  return filteredContent;
}