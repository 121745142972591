
export const filterHtml = (html) => {
	// filter every frameborder or scrolling attribute from iframes
	let newHtml = html.replaceAll(/ (frameborder|scrolling)=["'][^"']+["']/mg, '');

	// remove empty header tags
	newHtml = newHtml.replaceAll(/<h\d><\/h\d>/gm, '<p></p>');

	// add title attributes to iframes that don't have one
	let match;
	const titleregex = /<iframe(?!.*title=["'](.*)["'])[^>]*>/gm
	while ((match = titleregex.exec(newHtml)) != null) {
		newHtml = newHtml.substr(0,match.index) + '<iframe title="iframe title"' + newHtml.substr(match.index + 7, newHtml.length);
	}

	// anchor links fix
	newHtml = newHtml.replaceAll('https://#', '#')
	newHtml = newHtml.replaceAll('target="undefined"', '')
	return newHtml;
}
