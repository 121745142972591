import * as React from "react";
import { PropTypes } from "prop-types";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Slider from "react-slick";
import { Image } from "./Image";

export const Carousel = ({items}) => {
	const {t} = useTranslation();

  const [main, setMain] = React.useState(null)
  const [nav, setNav] = React.useState(null)
  const [slide, setSlide] = React.useState(0)
  const [showPopup, setShowPopup] = React.useState(false)

  const settings_main = {
		buttons: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
    beforeChange: (current, next) => {setSlide(next)}
	}

  const settings_nav = {
		buttons: false,
		speed: 500,
    centerMode: true,
		slidesToShow: 7,
		slidesToScroll: 1,
    focusOnSelect: true,
		responsive: [
			{
				breakpoint: 1364,
				settings: {
					slidesToShow: 5,
				}
			},
			{
				breakpoint: 980,
				settings: {
					slidesToShow: 3,
          centerPadding: "100px",
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
          centerPadding: "100px",
				}
			},
		]
	}
  
  const openPopup = function (slide) {
    main.slickGoTo(slide)
    setShowPopup(true)
  }
  return (
		<>
      <div className="c-slider__previews-wrapper">
        <div className="o-2-col -dist-3-1 gap-0 -sidearea c-slider__previews">
          <div className="c-slider__previews-highlight">
            <button aria-label="image 1" onClick={() => openPopup(0)}>
              <Image image={items[0].image}/>
            </button>
            <button className="c-slider__previews-button c-btn -tertiary -small" onClick={() => openPopup(0)}>{t('Alle foto\'s')}</button>
          </div>
          <div className="c-slider__previews-side">
            <button aria-label="image 2" onClick={() => openPopup(1)}>
              <Image image={items[1].image}/>
            </button>
            <button aria-label="image 3" onClick={() => openPopup(2)}>
              <Image image={items[2].image}/>
            </button>
            <button aria-label="image 4" onClick={() => openPopup(3)}>
              <Image image={items[3].image}/>
            </button>
          </div>
        </div>
      </div>
			<div className={`c-modal__wrapper -bg-dark c-modal__slider ${ !showPopup ? 'u-display-none' : ""}`}>
				<div className="c-modal  -large">
					<div className="c-modal__body">
						<Slider className="c-slider main"  asNavFor={nav} ref={(slider) => setMain(slider)} {...settings_main}>
              {items.map((item) => 
                <div className="c-slider__item-wrapper" key={'main'+item.url}>
                  <div className="c-slider__item">
                    <Image image={item.image}/>
                  </div>
                </div>
              )}
            </Slider>
            <div className="c-slider__counter "><i className="icon-fa-image-regular"></i><span>{slide+1}/{items.length}</span></div>
						<Slider className="c-slider nav" asNavFor={main} ref={(slider) => setNav(slider)} {...settings_nav}>
              {items.map((item) => 
                <div className="c-slider__item-wrapper" key={'nav'+item.url} >
                  <div className="c-slider__item">
                    <Image image={item.image}/>
                  </div>
                </div>
              )}
            </Slider>
					</div>
					<div className="c-modal__header">
						<div className="c-modal__close">
							<button className="icon-close" onClick={() => setShowPopup(false)}>
								<span>{t("Close")}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
  );
};

Carousel.propTypes = {
	items: PropTypes.array
};
