import * as React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { generateUrl } from '../utils/generateUrl';
import { GatsbyLink } from './Slices/GatsbyLink';
import { PropTypes } from 'prop-types'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

export const Header = ({ activeDocMeta }) => {
	const { t } = useTranslation();

	const [menuStateOpen, setMenuStateOpen] = React.useState(false);

	const toggleMenu = (event) => {
		event.preventDefault();
		setMenuStateOpen(!menuStateOpen)
	};
	
	const menu = React.useRef(null)
	React.useEffect(() => {
		function handleClickOutside(event) {
			if (!menu.current.contains(event.target)) {
				setMenuStateOpen(false)
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [menu]);

	const queryData = useStaticQuery(graphql`
		query HeaderQuery($lang: String) {
			prismicHeader(lang: {eq: $lang}) {
				_previewable
				data {
					body {
						... on PrismicHeaderDataBodyColumn {
							id
							primary {
								title
							}
							items {
								link {
									id
									url
									target
									type
									link_type
								}
								label
							}
						}
					}
				}
			}
		}
	`);
	
  const { data } = useMergePrismicPreviewData(queryData)

	return (
		<div className="region region-header">
			<div className="c-main-navbar-container">
				<header id="header" className="c-main-navbar" role="banner" ref={menu}>
					<nav className="c-main-navbar__skip-navigatie" aria-label={t('Skipnavigatie')}>
						<ul className="u-reset-list">
							<li className="u-m0">
								<a className="u-sr-only c-btn -secundary -small c-main-navbar__skip-link" href="#main-content">
									Ga naar de inhoud
								</a>
							</li>
						</ul>
					</nav>

					<nav className="o-stretch-wrapper u-bg-white" aria-label={t('Subnavigatie')}>
						<div className="o-wrapper u-p0 c-main-navbar__vlaanderen-header">
							<a href="https://www.vlaanderen.be/">
								<img src="/vlaanderen-logo.svg" alt="Naar vlaanderen.be" className="u-display-block" />
							</a>
						</div>
					</nav>

					<div className="o-stretch-wrapper u-bg-blue-900">
						<div className="o-wrapper c-navbar-consultants">
							<nav className="c-navbar-consultants__wrapper" aria-label={t('VDAB navigatie')}>
								<a className="c-navbar-consultants__vdab-logo" href="https://www.vdab.be">
									<img src="/vdab-logo-white.svg" alt="Naar VDAB startpagina" />
								</a>

								<div className="c-navbar-consultants__menu-button">
									<a className={menuStateOpen ? 'icon-close' : 'icon-mobile-menu'} href="#menu" onClick={toggleMenu}><span>Menu</span></a>
								</div>
								<div className={'c-navbar-consultants__menu-wrapper o-wrapper-stretch' + (menuStateOpen && ' show')}>
									<nav className={'c-navbar-consultants__menu'} aria-label={t('Mobiele navigatie')} style={{"--columns": data.prismicHeader.data.body.length}}>

										{data.prismicHeader.data.body.map((column) => {
											return (
												<div className="c-navbar-consultants__menu__column" key={'header-' + column.id}>
													<div className="c-navbar-consultants__menu__title">
														<h3>{column.primary.title}</h3>
													</div>
													<div className="c-navbar-consultants__menu__link_list">
														{column.items.map((link) => {
															return (
																<div className="c-navbar-consultants__menu__link" key={column.id + link.label}>
																	<GatsbyLink className="o-routing-link" link={link.link} label={link.label} />
																</div>
															)
														})}
													</div>
												</div>
											)
										})}
									</nav>
								</div>

								<Link to={generateUrl(activeDocMeta.lang, '')} className="c-navbar-consultants__item-link u-p0 u-text-color-white">{t('Infopunt e-leren')}</Link>
							</nav>
						</div>
					</div>
				</header>

			</div>
		</div>
	)
}

Header.propTypes = {
	activeDocMeta: PropTypes.object
}