import React from 'react'
import {Search} from './Search';
import {graphql, useStaticQuery} from "gatsby";
import { useStore } from 'react-stores';
import { searchWrapperStore } from './../stores/searchWrapperStore';
import { PropTypes } from 'prop-types';

export const SearchWrapper = ({source}) => {
	const searchStoreState = useStore(searchWrapperStore);

	const queryData = useStaticQuery(graphql`
		query SearchWrapperQuery {
		localSearchPages {
				publicIndexURL
				publicStoreURL        
			}
		}
	`);

	const ttl = 60 * 60 * 1000;
	const [searchStore, setSearchStore] = React.useState(() => {
		let initialValue = null;
		if (typeof window !== 'undefined') {
			initialValue = searchStoreState.store;
			if (initialValue) {
				if (typeof initialValue.expire !== 'undefined' && Date.now() <= initialValue.expire) {
					initialValue = initialValue.value;
				} else {
					initialValue = null;
				}
			}
		}
		return initialValue || null;
	})
	const [searchIndex, setSearchIndex] = React.useState(() => {
		let initialValue = null;
		if (typeof window !== 'undefined') {
			initialValue = searchStoreState.index;
			if (initialValue) {
				if (typeof initialValue.expire !== 'undefined' && Date.now() <= initialValue.expire) {
					initialValue = initialValue.value;
				} else {
					initialValue = null;
				}
			}
		}
		return initialValue || null;
	})

	const getData = React.useCallback(async () => {
		if (searchIndex === null) {
			const response = await fetch(queryData.localSearchPages.publicIndexURL);
			const index = await response.text();
			if (typeof window !== 'undefined') {
				let state = searchWrapperStore.state;
				state.index = {
					value: index,
					expire: Date.now() + ttl
				};
				searchWrapperStore.setState(state);
			}
			setSearchIndex(index);
		}
		if (searchStore === null) {
			const response2 = await fetch(queryData.localSearchPages.publicStoreURL);
			const store = await response2.json();
			if (typeof window !== 'undefined') {
				let state = searchWrapperStore.state;
				state.store = {
					value: store,
					expire: Date.now() + ttl
				};
				searchWrapperStore.setState(state);
			}
			setSearchStore(store);
		}
	}, [queryData, ttl, searchIndex, searchStore]);

	React.useEffect(() => {
		getData()
	}, [getData]);

	return (
		<>
			{ (searchStore && searchIndex) &&
					<Search
						source={source}
						store={searchStore}
						index={searchIndex}
					/>
				}
		</>
	)
};

SearchWrapper.propTypes = {
	source: PropTypes.string
}