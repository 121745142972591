import { getCookie, validGTMTrackingId } from 'gatsby-plugin-gdpr-cookies/helper'
import { trackGoogleTagManager } from 'gatsby-plugin-gdpr-cookies/services/google-tag-manager'


export const initializeAndTrackGoogleTagManagerOther = function (options, location) {
  if (getCookie(options.cookieName) === "true" && validGTMTrackingId(options)) {
    var environmentParamStr = "";

    if (options.gtmAuth && options.gtmPreview) {
      environmentParamStr = "&gtm_auth=" + options.gtmAuth + "&gtm_preview=" + options.gtmPreview + "&gtm_cookies_win=x";
    }

    addGoogleTagManager(options, environmentParamStr).then(function (status) {
      if (status) {
        initializeGoogleTagManager(options);
        trackGoogleTagManager(options, location);
      }
    });
  }
};

function addGoogleTagManager(_ref, environmentParamStr) {
  var {trackingId, dataLayerName} = _ref;
  return new Promise(function (resolve) {
    if (window.gatsbyPluginGDPRCookiesGoogleTagManagerOtherAdded) return resolve(true);
    /* eslint-disable */

    !function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + environmentParamStr;
      f.parentNode.insertBefore(j, f);
    }(window, document, 'script', dataLayerName, trackingId);
    /* eslint-enable */

    var iframe = document.createElement('iframe');
    iframe.key = 'gatsby-plugin-gdpr-cookies-google-tagmanager-iframe';
    iframe.src = "https://www.googletagmanager.com/ns.html?id=" + trackingId + environmentParamStr;
    iframe.height = 0;
    iframe.width = 0;
    iframe.style = "display: none; visibility: hidden";
    document.body.insertBefore(iframe, document.body.firstChild);
    window.gatsbyPluginGDPRCookiesGoogleTagManagerOtherAdded = true;
    resolve(true);
  });
}

function initializeGoogleTagManager(options) {
  if (
    !window.gatsbyPluginGDPRCookiesGoogleTagManagerOtherInitialized &&
    getCookie(options.cookieName) === `true` &&
    validGTMTrackingId(options)
  ) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function(){window.dataLayer.push(arguments);}
    window.gtag('js', new Date())
  
    let gaAnonymize = options.anonymize
    let gaAllowAdFeatures = options.allowAdFeatures
    gaAnonymize = gaAnonymize !== undefined ? gaAnonymize : true
    gaAllowAdFeatures = gaAllowAdFeatures !== undefined ? gaAllowAdFeatures : true
  
    window.gtag('config', options.trackingId, {
      'anonymize_ip': gaAnonymize,
      'allow_google_signals': gaAllowAdFeatures
    })
  }
}