import * as React from "react";
import { Image } from "./Image";
import { PropTypes } from 'prop-types'
import { filterHtml } from '../../utils/filterHtml';

export const Podcast = ({ item }) => {
  return (
    <div className="c-podcast" id={item.anchor?.replace(' ','-')}>
      <div className="c-podcast__content">
        {item.thumbnail?.url && 
          <div className="c-podcast__image">
            <Image image={item.thumbnail} />
          </div>
        }
        <div className="c-podcast__body">
          <div className="c-podcast__title">
            <h3>{item.podcast_title}</h3>
          </div>
          <div className="c-podcast__description" dangerouslySetInnerHTML={{__html: filterHtml(item.podcast_description?.html)}}>
          </div>
        </div>
      </div>

      <div className='c-podcast__embed' dangerouslySetInnerHTML={{__html: filterHtml(item.podcast?.html)}}></div>
    </div>
  );
};

Podcast.propTypes = {
	item: PropTypes.object
}