import * as React from "react";
import { PropTypes } from "prop-types";
import { filterHtml } from "../../utils/filterHtml";
import { Link } from "gatsby";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { SliceZone } from "../SliceZone";

export const BlogPopup = ({ page, index, otherLinks }) => {
	const {t} = useTranslation();
  let showPopup = false;
  if (typeof window !== 'undefined' && window?.location?.hash === "#"+page.id) {
    showPopup = true
  }
	let filteredBody = JSON.stringify(page.data.body);
	otherLinks.forEach(element => {
		filteredBody = filteredBody.replace(element.url, '#'+element.id)
	});
	const newBody = JSON.parse(filteredBody);
  return (
		<>
			<article className={`c-navigation-block__item`}>
				<h3 className="c-navigation-block__title">
					<Link className="o-routing-link secondary" to={`#${page.id}`}>{index}. {page.data.title.text}</Link>
				</h3>
				<div className="date u-display-none">{new Date(page.last_publication_date).toLocaleDateString(page.lang, { year: 'numeric', month: 'long', day: 'numeric' })}</div>
				{page.data.abstract?.html && <div className="c-navigation-block__content" dangerouslySetInnerHTML={{ __html: filterHtml(page.data.abstract.html) }} />}
			</article>
			<div className={`c-modal__cookie-overlay c-modal__event-popup ${ !showPopup ? 'u-display-none' : ""}`}>
				<div className="c-modal -large ">
					<div className="c-modal__header">
						<div className="c-modal__title">
							<h2>{page.data.title.text}</h2>
						</div>
						<div className="c-modal__close">
							<a className="icon-close" href="#close">
								<span>{t("Close")}</span>
							</a>
						</div>
					</div>
					<div className="c-modal__body">
						<div className="c-modal__intro" dangerouslySetInnerHTML={{__html: filterHtml(page.data.intro.html)}} />
						<div className="c-modal__description">
							<SliceZone slices={newBody} />
						</div>
					</div>
				</div>
			</div>
		</>
  );
};

BlogPopup.propTypes = {
  page: PropTypes.object,
	index: PropTypes.number,
	otherLinks: PropTypes.array
};
