import * as React from "react";
import { PropTypes } from "prop-types";
import { filterHtml } from "../utils/filterHtml";
import { GatsbyLink } from "./Slices/GatsbyLink";

export const NewsletterBanner = ({ title, description, cta_link, cta_label }) => {

	const link = {
		url: cta_link.url.replace('https:///', '/'),
		link_type: "Document"
	}

  return (
    <section className="region region-newsletter-banner">
      <div className="o-stretch-wrapper u-bg-blue-700">
				<div className="o-wrapper u-mb0">
					<div className="o-2-col -sidearea">
						<div>
							<h2 className="u-text-color-white">{title}</h2>
							<div className="o-intro-text -on-dark-bg" dangerouslySetInnerHTML={{ __html: filterHtml(description) }}/>
						</div>
						<div className="-is-sidearea u-display-flex u-justify-content-center u-align-items-center">
							<GatsbyLink className="c-btn -primary" link={link} label={cta_label} />
						</div>
					</div>
				</div>
			</div>
    </section>
  );
};

NewsletterBanner.propTypes = {
  title: PropTypes.string,
	description: PropTypes.string,
	cta_link: PropTypes.object,
	cta_label: PropTypes.string
};
