import React, { useState, useRef, useEffect } from 'react'
import { useFlexSearch } from 'react-use-flexsearch'
import { graphql, useStaticQuery, Link } from "gatsby";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { PropTypes } from 'prop-types';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'


export const Search = ({ source, index, store }) => {
	const { t } = useTranslation();

	const [query, setQuery] = useState('')
	const [hideResults, setHideResults] = useState(true);

	const footer = (source === 'footer');

	const queryData = useStaticQuery(graphql`
    query SearchQuery($lang: String) {
			prismicSearchResults(lang: {eq: $lang}) {
				_previewable
				data {
					suggestions {
						search_suggestion
					}
				}
			}
    }
  `);
  const { data } = useMergePrismicPreviewData(queryData)

	const results = useFlexSearch(query, index, store, { limit: 10 })

	const searchInput = useRef(null);
	const list = useRef(null)
	useEffect(() => {
		function handleClickOutside(event) {
			if (!list.current.contains(event.target)) {
				setHideResults(true);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [list]);

	const onClickTag = (event, text) => {
		event.preventDefault();
		setQuery(text);
		setHideResults(false);
		searchInput.current.focus();
	};

	const style = {
		'--image': 'url(/vrouw_vergrootglas.svg)'
	}

	return (
		<div className={'c-search-widget__' + source + (footer ? ' u-bg-blue-100 o-stretch-wrapper' : '')}>
			<div className="c-search-widget o-wrapper" style={style}>
				<div className="c-search-widget__form-block ">
					<form className="basic-form c-search-widget__form" onSubmit={(e) => e.preventDefault()}>
						<div className="form-row c-search-widget__field-column">
							<div className="form-label">
								<label className="c-search-widget__label" htmlFor="search">
									{footer ?
										<h3>{t('Waarmee kunnen we je helpen?')}</h3> :
										<h4>{t('Waarmee kunnen we je helpen?')}</h4>}
								</label>
							</div>
							<div className="form-field">
								<div className="form-field-icon field-search">
									<div className="awesomplete c-search-widget__input" ref={list}>
										<input type="search" name="Trefwoord"
											id="search" autoComplete="off" ref={searchInput}
											value={query} onInput={(e) => { setQuery(e.target.value); setHideResults(false) }} />

										<ul className={"dropdown-menu hs-slide-over " + (results.length ? "results" : "")} style={{ 'display': (query.length > 0 && hideResults !== true) ? 'block' : 'none' }}>
											<li className="option title showResults"><span className="title">{t('Topresultaten')}</span></li>
											{results.length ?
												results.map((result) => (
													<li className="option link" key={result.id}>
														<Link to={result.url} state={{ from: result.id }}><span className="title icon-arrow-right">{result.title}</span></Link>
													</li>
												)) : ""
											}
											<li className="option showResults allResults"><Link to={"/" + t("search-results")} state={{ query: query }}><span className="title action-go-after">{t('Alle zoekresultaten')}</span></Link></li>
											<li className="option noResults"><span>{t('Geen resultaten gevonden')}</span></li>
										</ul>
									</div>
									<span className="field-icon icon-search" />
								</div>
							</div>
						</div>
					</form>
					{(footer) &&
						<div className="c-search-widget__filters">
							<p className="c-search-widget__filters-label">{t('Bijvoorbeeld')}: </p>
							{data.prismicSearchResults.data.suggestions.map((suggestion) => (
								<div key={'suggestion-' + suggestion.search_suggestion} className="search-suggestion"><a href="#search" className="search-suggestion_link" onClick={(e) => onClickTag(e, suggestion.search_suggestion)}>{suggestion.search_suggestion}</a></div>
							))}
						</div>
					}
				</div>
			</div>
		</div>
	)
};

Search.propTypes = {
	source: PropTypes.string,
	index: PropTypes.string,
	store: PropTypes.object
}