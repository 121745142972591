import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "../context/AppContext";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StepCounter } from "./StepCounter";
import { ProfileModalButtons } from "./ProfileModalButtons";

export const ProfileModal = () => {
  const { t } = useTranslation();
  const context = useContext(AppContext);
  const contextRef = useRef(context)

  const [step, setStep] = useState(0);
  const prevStep = useRef(0);
  const [answers, setAnswers] = useState({ 1: "", 2: [], 3: [] });
  const [profile, setProfile] = useState(null);
  const steps = [
    {
      id: "level",
      type: "radio",
      class: "radiobutton",
      question: `1. ${t("Do you have experience with e-learning?")}`,
      answers: [t("Not yet"), t("A little"), t("Yes")],
    },
    {
      id: "types",
      type: "checkbox",
      class: "checkbox",
      question: `2. ${t("Which way(s) of e-learning do you prefer?")}`,
      answers: [
        t("Tekst gebaseerde cursussen"),
        t("Cursus met video’s"),
        t("Interactieve modules met oefeningen"),
        t("Een virtueel klaslokaal met live lessen"),
        t("Gesproken cursus (podcast)"),
      ],
    },
    {
      id: "themes",
      type: "checkbox",
      class: "checkbox",
      question: `3. ${t(
        "Wat zijn voor jou de belangrijkste uitdagingen bij blended leren?"
      )}`,
      answers: [
        t("Technische problemen"),
        t("Moeilijkheid om mensen te motiveren"),
        t("Gebrek aan sociale interactie of persoonlijke begeleiding"),
        t("Wat zegt de wet inzake privacy?"),
        t("De kostprijs om het op te zetten"),
        t("Geen specifieke uitdaging, ik wil graag meer weten"),
      ],
    },
  ];

  const handleBack = () => {
    if (step > 0) {
      setStep(step > 0 ? step - 1 : 0);
    }
  };

  useEffect(() => {
    const profiles = {
      1: {
        key: "debutant",
        name: t("Dolle Debutant"),
        description: t(
          "Alle begin lijkt moeilijk, maar samen helpen we je op weg in de wondere wereld van Blended Leren."
        ),
      },
      2: {
        key: "medior",
        name: t("Machtige Medior"),
        description: t(
          "Je kent al wat snufjes van Blended Leren, maar we helpen je graag op weg naar nog meer expertise. "
        ),
      },
      3: {
        key: "expert",
        name: t("Efficiënte Expert"),
        description: t(
          "Jij vindt duidelijk je weg al in het Blended Leren. We bieden je tal van info naar nog meer expertise. "
        ),
      },
    };
    const types = {
      1: "Text",
      2: "Video",
      3: "Interactive",
      4: "Live",
      5: "Audio",
    };
    const themes = {
      1: "thema-technische-ondersteuning",
      2: "thema-enthousiasmeren",
      3: "thema-didactische-ondersteuning",
      4: "thema-juridische-ondersteuning",
      5: "thema-financiele-ondersteuning",
    };

    if (prevStep.current === 1 && answers[1]) {
      setProfile(profiles[answers[1]]);
      contextRef.current.setLevel(profiles[answers[1]].key);
    }
    if (prevStep.current === 2 && answers[2]) {
      contextRef.current.setTypes(answers[2].map((index) => {return types[index]}))
    }
    if (prevStep.current === 3 && answers[3] && answers[3] != 6) {
      contextRef.current.setThemes(answers[3].map((index) => {return themes[index]}))
    }
    prevStep.current = step
}, [step, t, contextRef, answers])


  const handleAnswer = (type, step, answer) => {
    if (type === "radio") {
      setAnswers({ ...answers, [step]: answer });
    } else if (type === "checkbox" && checked(type, step, answer)) {
      let newarr = answers[step];
      newarr.splice(answers[step].indexOf(answer), 1);
      setAnswers({ ...answers, [step]: newarr });
    } else {
      const newarr = answers[step].concat([answer]);
      setAnswers({ ...answers, [step]: newarr });
    }
  };

  const handleClose = (skip = false) => {
    context.setHideProfileModal(true);
    context.setShowProfile(!skip);
  };

  const checked = (type, step, answer) => {
    if (type === "radio") {
      return answers[step] === answer;
    }
    return answers[step].includes(answer);
  };

  return (
    context.getCookieModalState() &&
    !context.hideProfileModal && (
      <div className="c-modal__cookie-overlay" id="profile">
        <div className="c-modal c-modal__profile -large -full-screen@tablet">
          {step !== 4 && (
            <div className="c-modal__header">
              <div className="c-modal__close">
                <button onClick={() => handleClose(true)}>
                  {t("Overslaan")}
                </button>
              </div>
            </div>
          )}
          <div className="c-modal__body -form">
            {step !== 4 && (
              <h2 className="c-modal__title">
                {t("Welkom op Infopunt e-leren.")}
              </h2>
            )}
            <StepCounter step={step} setStep={setStep} />
            <section className="steps">
              <div className="main">
                {step === 0 && (
                  <div className="step0">
                    <div className="">
                      <h4>{t("Hallo daar!")}</h4>
                      <p>
                        {t(
                          "Ben je actief in het professioneel aanbieden of ontwikkelen van opleidingen?"
                        )}
                        <br />
                        {t(
                          "Op ons platform kom je alles te weten over blended learning en e-leren. We willen je graag wat beter leren kennen, zodat we informatie op maat kunnen aanreiken."
                        )}
                      </p>
                      <img id="intro" src="/profiles/rafiki.png" alt="rafiki" />
                    </div>
                  </div>
                )}
                {steps.map((value, index) => (
                  <React.Fragment key={value.id}>
                    {step === index + 1 && (
                      <div className={`step${index + 1} question`}>
                        <h5>{value.question}</h5>
                        {value.answers.map((answer, answerIndex) => (
                          <label
                            key={answer}
                            htmlFor={`profile-q${index + 1}-a${
                              answerIndex + 1
                            }`}
                            className={value.class}
                          >
                            <input
                              type={value.type}
                              name={`profile-q${index + 1}`}
                              id={`profile-q${index + 1}-a${answerIndex + 1}`}
                              value={`${answerIndex + 1}`}
                              checked={checked(
                                value.type,
                                index + 1,
                                answerIndex + 1
                              )}
                              onChange={() =>
                                handleAnswer(
                                  value.type,
                                  index + 1,
                                  answerIndex + 1
                                )
                              }
                              autoComplete="off"
                            />
                            {answer}
                          </label>
                        ))}
                      </div>
                    )}
                  </React.Fragment>
                ))}
                {step === 4 && (
                  <div className="step4 result">
                    <h2>
                      {t(
                        "Proficiat, je eerste stappen zijn gezet. Jouw profiel is"
                      )}
                      :
                    </h2>
                    <div className="profile">
                      <img
                        src={`/profiles/${profile.key}.svg`}
                        alt={profile.key}
                      />
                      <h3 className="h1">{profile.name}</h3>
                    </div>
                    <h3>{profile.description}</h3>
                  </div>
                )}
              </div>
              <footer>
                <ProfileModalButtons
                  step={step}
                  profile={profile}
                  setStep={setStep}
                  handleBack={handleBack}
                  handleClose={handleClose}
                />
              </footer>
            </section>
          </div>
        </div>
      </div>
    )
  );
};
