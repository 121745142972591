import * as React from 'react'
import { graphql, Link } from "gatsby";
import { Layout } from '../components/Layout'
import { Hero } from '../components/Hero'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Image } from '../components/Slices/Image';
import { GatsbyLink } from '../components/Slices/GatsbyLink';
import { PropTypes } from 'prop-types';
import { filterHtml } from "../utils/filterHtml";
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const CaseStudies = ({ data, location }) => {
  const { t } = useTranslation();

	const title = data.prismicCasesOverview.data.title.text;
	const desc = data.prismicCasesOverview.data.description.text;
	const { lang, type, url } = data.prismicCasesOverview || {}
	const alternateLanguages = data.prismicCasesOverview.alternate_languages || []
	const activeDoc = { lang, type, url, alternateLanguages, title, desc }

  const showSearch = true;
  const [pageLoaded, setPageLoaded] = React.useState(false)
  const [promotors] = React.useState([...new Set(data.allPrismicCaseStudy.nodes.map((item) => (item.data.promotor)))])
  const [provincies] = React.useState([...new Set(data.allPrismicCaseStudy.nodes.map((item) => (item.data.provincie)))])
  const [sectors] = React.useState([...new Set(data.allPrismicCaseStudy.nodes.map((item) => (item.data.sector)))])
  const [dropdowns, setDropdowns] = React.useState({
    provincies: false,
    sectors: false,
    promotors: false,
  })
  const [filters, setFilters] = React.useState(location.state?.filters || {
    search: "",
    provincies: [],
    promotors: [],
    sectors: []
  })
  const [filtersShown, setFiltersShown] = React.useState({
    provincies: provincies,
    promotors: promotors,
    sectors: sectors
  })
  const [allCases] = React.useState(data.allPrismicCaseStudy.nodes)
  const [casesShown, setCasesShown] = React.useState(data.allPrismicCaseStudy.nodes)
  const [mappedFields] = React.useState({
    titles: data.allPrismicCaseStudy.nodes.map((item) => (item.data.title.text.toLowerCase())),
    abstracts: data.allPrismicCaseStudy.nodes.map((item) => (item.data.abstract.text.toLowerCase())),
    bodys: data.allPrismicCaseStudy.nodes.map((item) => (item.data.body.text.toLowerCase())),
    promotors: data.allPrismicCaseStudy.nodes.map((item) => (item.data.promotor)),
    provincies: data.allPrismicCaseStudy.nodes.map((item) => (item.data.provincie)),
    sectors: data.allPrismicCaseStudy.nodes.map((item) => (item.data.sector)),
    uids: data.allPrismicCaseStudy.nodes.map((item) => (item.uid)),
  })
  const [dropdownLength, setDropdownLength] = React.useState('0px')

  const updateFilter = (source, value, referenceFilter = false) => {
    let newFilters = filters
    if (source === 'search') {
      newFilters.search = value
      if (referenceFilter) {
        document.querySelector('input[id="search"]').value = "";
      }
    } else if (value.value) {
        newFilters[source].push(value.name)
    } else {
      newFilters[source].splice(newFilters[source].indexOf(value.name), 1)
    }
    setFilters(newFilters)

    continueUpdateFilter(source, filters, value, referenceFilter);
  }

  const continueUpdateFilter = (source, filters, value, referenceFilter) => {
    if (filters.search !== "" || filters.provincies.length || filters.promotors.length || filters.sectors.length) {
      continueUpdateFilterNext(filters)
    } else {
      setCasesShown(allCases)
      setFiltersShown({
        promotors: promotors,
        provincies: provincies,
        sectors: sectors
      })
    }

    if (referenceFilter && source !== 'search') {
      document.querySelector('input[id="' + value.name + '"]').checked = false;
    }
  };

  const continueUpdateFilterNext = (filters) => {
    let cases = []
    let filteredFilters = {
      provincies: [],
      promotors: [],
      sectors: []
    }

    for (let i = 0; i < mappedFields.titles.length; i++) {
      let search = filters.search === "" || mappedFields.titles[i].includes(filters.search.toLowerCase()) || mappedFields.abstracts[i].includes(filters.search.toLowerCase()) || mappedFields.bodys[i].includes(filters.search.toLowerCase());
      if (search && (filters.provincies.includes(mappedFields.provincies[i]) || !filters.provincies.length) && (filters.promotors.includes(mappedFields.promotors[i]) || !filters.promotors.length) && (filters.sectors.includes(mappedFields.sectors[i]) || !filters.sectors.length)) {
        cases.push(allCases[i]);
        (filteredFilters.provincies.indexOf(mappedFields.provincies[i]) === -1) && filteredFilters.provincies.push(mappedFields.provincies[i]);
        (filteredFilters.promotors.indexOf(mappedFields.promotors[i]) === -1) && filteredFilters.promotors.push(mappedFields.promotors[i]);
        (filteredFilters.sectors.indexOf(mappedFields.sectors[i]) === -1) && filteredFilters.sectors.push(mappedFields.sectors[i]);
      }
    }
    setCasesShown(cases)
    //setFiltersShown(filteredFilters)
  }

  const toggleDropdown = (source = '', action = "") => {
    let newDropdowns = {
      provincies: false,
      sectors: false,
      promotors: false,
    }
    if (source !== '') {
      let button = document.querySelector('.field-dropdown.field-' + source);
      setDropdownLength((window.innerHeight - button.getBoundingClientRect().top - button.clientHeight) + 'px')
      if (action === "open") {
        newDropdowns[source] = true
      } else if (action === "close") {
        newDropdowns[source] = false
      } else {
        newDropdowns[source] = !dropdowns[source]
      }
    }
    setDropdowns(newDropdowns)
  }
  const ref = React.useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      toggleDropdown();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const updatePromotors = (input) => {
    let showPromotors = []
    toggleDropdown('promotors', 'open')
    promotors.forEach((element) => {
      if (element.toLowerCase().includes(input.toLowerCase())) {
        showPromotors.push(element)
      }
    });
    filtersShown.promotors = showPromotors;
  }

  if (location.state?.filters && !pageLoaded) {
    setPageLoaded(true)
    continueUpdateFilterNext(location.state.filters)
  }

  const sortByCheckedAlpha = (sourceVar, property) => {
    return sourceVar.sort((a, b) => {
      let aChecked = filters[property].includes(a);
      let bChecked = filters[property].includes(b);
      if ((aChecked && bChecked) || (!aChecked && !bChecked)) {
        return a.localeCompare(b);
      } else {
        return bChecked ? 1 : -1;
      }
    })
  };
  const provinceList = () => {
    return sortByCheckedAlpha(provincies, 'provincies');
  };
  const sectorList = () => {
    return sortByCheckedAlpha(sectors, 'sectors');
  };
  const promotorList = () => {
    return sortByCheckedAlpha(promotors, 'promotors');
  }
  return (
    <Layout activeDocMeta={activeDoc} pageData={data.prismicCasesOverview} themes={data.allPrismicTheme} hideBreadcrumb>
      <Hero title={data.prismicCasesOverview.data.title.text} showImage={false} >
        <div dangerouslySetInnerHTML={{ __html: filterHtml(data.prismicCasesOverview.data.intro.html) }}></div>
      </Hero>

      <div className="o-stretch-wrapper">
        <div className="c-banner o-wrapper">
          <div className="o-2-col -sidearea  c-banner-block ">
            <div className="c-banner-block__textblock">
              <div dangerouslySetInnerHTML={{ __html: filterHtml(data.prismicCasesOverview.data.description.html) }} />
            </div>
            <div className="c-banner-block__imageblock is-sidearea">
              <Image className="c-banner-block__image" image={data.prismicCasesOverview.data.eu_logo} />
            </div>
          </div>
        </div>
      </div>


      <div className="o-stretch-wrapper">
        <div className="o-wrapper -spacing-y-xxl">
          <h2>{data.prismicCasesOverview.data.results_title.text + " (" + data.allPrismicCaseStudy.nodes.length + ")"}</h2>

          <div className="o-wrapper">
            <form className="form-block basic-form filter-block" ref={ref}>
              <div className="form-field field-dropdown field-provincies">
                <div className="form-field-icon" onClick={() => toggleDropdown('provincies')} onKeyDown={() => toggleDropdown('provincies')} role="button" tabIndex={0}>
                  <div className="field-button" >{t('Provincie')}</div>
                  <span className={"field-icon icon-arrow-" + (dropdowns.provincies ? 'up' : 'down')} />
                </div>
                <div className={`form-field-list ${(dropdowns.provincies ? 'open' : '')}`} style={{ '--dropdown-length': dropdownLength }}>
                  {provinceList().map((provincie) => (
                    <div className="checkbox" key={'provincie-' + provincie}>
                      <input type="checkbox" name={provincie} id={provincie} onInput={(e) => { updateFilter('provincies', { name: provincie, value: e.target.checked }) }} />
                      <label htmlFor={provincie}>{provincie}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="form-field field-dropdown field-sectors">
                <div className="form-field-icon" onClick={() => toggleDropdown('sectors')} onKeyDown={() => toggleDropdown('sectors')} role="button" tabIndex={0}>
                  <div className="field-button" >{t('Sector')}</div>
                  <span className={"field-icon icon-arrow-" + (dropdowns.sectors ? 'up' : 'down')} />
                </div>
                <div className={`form-field-list ${(dropdowns.sectors ? 'open' : '')}`} style={{ '--dropdown-length': dropdownLength }}>
                  {sectorList().map((sector) => (
                    <div className="checkbox" key={'sector-' + sector}>
                      <input type="checkbox" name={sector} id={sector} onInput={(e) => { updateFilter('sectors', { name: sector, value: e.target.checked }) }} />
                      <label htmlFor={sector}>{sector}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="form-field field-dropdown field-promotors">
                <div className="form-field-icon field-search">
                  <input type="search" placeholder={t('Zoek een promotor')} name="Trefwoord" id="promotor" autoComplete="on" onFocus={() => toggleDropdown('promotors', 'open')} onInput={(e) => { updatePromotors(e.target.value) }} />
                  <span className="field-icon icon-search" />
                </div>
                <div className={`form-field-list ${(dropdowns.promotors ? 'open' : '')}`} style={{ '--dropdown-length': dropdownLength }}>
                  {promotorList().map((promotor) =>  (
                    <div className={ "checkbox" + (!filtersShown.promotors.includes(promotor) && !filters.promotors.includes(promotor) ? ' hide' : '')} key={'promotor-' + promotor}>
                      <input type="checkbox" name={promotor} id={promotor} onInput={(e) => { updateFilter('promotors', { name: promotor, value: e.target.checked }) }} />
                      <label htmlFor={promotor}>{promotor}</label>
                    </div>
                  ))}
                </div>
              </div>
              {showSearch && (
                <div className="form-field">
                  <div className="form-field-icon field-search">
                    <input type="search" placeholder={t('Zoek een case')} name="Trefwoord" id="search" autoComplete="off" onInput={(e) => { updateFilter('search', e.target.value) }} />
                    <span className="field-icon icon-search" />
                  </div>
                </div>
              )}
            </form>

            {(filters.provincies.length > 0 || filters.promotors.length > 0 || filters.sectors.length > 0 || filters.search.length > 0) && (
              <div className="filters-list">
                {filters.provincies.sort().length > 0 && (
                  <div className="provincies">
                    {filters.provincies.map(filter => (
                      <button key={'button-provincie-' + filter} onClick={() => updateFilter('provincies', { name: filter, value: false }, true)} onKeyDown={() => updateFilter('provincies', { name: filter, value: false })} className="remove-filter icon-close"><span>{filter}</span></button>
                    ))}
                  </div>
                )}
                {filters.sectors.sort().length > 0 && (
                  <div className="sectors">
                    {filters.sectors.map(filter => (
                      <button key={'button-sector-' + filter} onClick={() => updateFilter('sectors', { name: filter, value: false }, true)} onKeyDown={() => updateFilter('sectors', { name: filter, value: false })} className="remove-filter icon-close"><span>{filter}</span></button>
                    ))}
                  </div>
                )}
                {filters.promotors.sort().length > 0 && (
                  <div className="promotors">
                    {filters.promotors.map(filter => (
                      <button key={'button-promotor-' + filter} onClick={() => updateFilter('promotors', { name: filter, value: false }, true)} onKeyDown={() => updateFilter('promotors', { name: filter, value: false })} className="remove-filter icon-close"><span>{filter}</span></button>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="c-cases">
            {Object.values(casesShown).map(case_study => (
              <div className={"c-case__item" + (case_study.data.testimonial ? " testimonial" : "")} id={case_study.uid} key={case_study.id} >
                <div className="c-case__item__header">
                  <Link className="c-case__item__title" to={case_study.url} state={{filters: filters}}>
                    <h3>{case_study.data.title.text}</h3>
                  </Link>
                  <div className="c-case__item__provincie">{case_study.data.provincie}</div>
                </div>
                <div className="c-case__item__content" dangerouslySetInnerHTML={{ __html: filterHtml(case_study.data.abstract.html) }} />
                <div className="c-case__item__promotor">{t('Organisator') + ': ' + case_study.data.promotor}</div>
                <div className="c-case__item__footer">
                  {case_study.data.links.length > 0 && (
                    <div className="c-case__item__training">
                      <span>{t('Opleiding')}: </span>
                      <div className="c-case__item__links">
                        {case_study.data.links.map((link) => {
                          return (
                            <div className="c-case__item__link" key={`training-link-${link.label}`}>
                              <GatsbyLink link={link.link} label={link.label} />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                  {case_study.data.testimonial &&
                    <Link to={`${case_study.url}#video`} state={{filters: filters}} className="c-case__item__testimonial"><span>{t('Bekijk de video')}</span><img src="/images/icons/video-screen.svg" alt="video" /></Link>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
};

CaseStudies.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default withPrismicPreview(CaseStudies);

export const query = graphql`
query CaseStudiesQuery($lang: String, $id: String, $locale: String) {
  locales: allLocale(filter: {language: {eq: $locale}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  prismicCasesOverview(id: {eq: $id}, lang: {eq: $lang}) {
    _previewable
    type
    lang
    alternate_languages {
      document {
        ... on PrismicCasesOverview {
          url
        }
      }
      lang
    }
    url
    data {
      description {
        html
        text
      }
      eu_logo {
        alt
        localFile {
          publicURL
        }
      }
      intro {
        html
      }
      results_title {
        text
      }
      title {
        text
      }
    }
  }
  allPrismicCaseStudy(
    filter: {lang: {eq: $lang}}
  ) {
    nodes {
      _previewable
      id
      uid
      url
      data {
        promotor
        provincie
        sector
        abstract {
          html
          text
        }
        body {
          html
          text
        }
        testimonial
        title {
          text
        }
        links {
          link {
            link_type
            url
            type
            target
            document {
              ... on PrismicLevel2Faq {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicEditorial {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicCookiePolicy {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicPrivacy {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicLevel2 {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicLevel3Step {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicLevel3Faq {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicCaseStudy {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicDisclaimer {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicHome {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicTheme {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicCasesOverview {
                id
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
          label
        }
      }
    }
  }
  allPrismicTheme(
    filter: {lang: {eq: $lang}}
    sort: {fields: data___weight, order: ASC}
  ) {
    nodes {
      _previewable
      id
      data {
        title {
          text
        }
      }
      prismicId
      url
    }
  }
}
`;
