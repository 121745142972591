import * as React from 'react'
import * as Gatsby from 'gatsby'
import { PropTypes } from 'prop-types'

export const GatsbyLink = ({ link, label, className, afterClassName }) => {

	return (
		<>
			{link?.link_type === "Document"
        ? <Gatsby.Link className={className} to={link?.url}>{label || link?.document?.data?.title?.text}{afterClassName && <span className={afterClassName}></span>}</Gatsby.Link>
        : <a className={className} href={link?.url?.replace('https://#', '#')} target={link?.target} rel="noopener noreferrer">{label || link?.url}{afterClassName && <span className={afterClassName}></span>}</a>
      }
		</>
	)
}

GatsbyLink.propTypes = {
	link: PropTypes.object,
	label: PropTypes.string,
	className: PropTypes.string,
	afterClassName: PropTypes.string
}