import * as React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { SliceZone } from "../components/SliceZone";
import { filterHtml } from "../utils/filterHtml";
import { PropTypes } from "prop-types";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const CookiePolicy = ({ data }) => {
  if (!data) return null;

  const page = data.prismicCookiePolicy || {};
  const { lang, type, url } = page || {};
  const title = page.data.title.text;
  const desc = page.data.meta_description;

  const alternateLanguages = page.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    title,
    desc,
  };

  return (
    <Layout activeDocMeta={activeDoc} hideCookieNotification>
      <div className="o-wrapper">
        <h1 className="c-intro-block__title">{page.data.title.text}</h1>
        <div
          className="o-intro-text"
          dangerouslySetInnerHTML={{ __html: filterHtml(page.data.intro.html) }}
        />
        <div className="c-divider u-mb0" />
      </div>

      <div className="o-wrapper">
        <div className="o-1-col">
          <SliceZone slices={page.data.body} />
        </div>
      </div>
    </Layout>
  );
};

export default withPrismicPreview(CookiePolicy);

export const query = graphql`
  query MyCookiePolicyQuery($lang: String, $id: String, $locale: String) {
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    prismicCookiePolicy(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      data {
        body {
          ... on PrismicCookiePolicyDataBodyImage {
            id
            primary {
              image {
                url
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1716
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                  publicURL
                }
                thumbnails {
                  mobile {
                    url
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 1230
                          layout: CONSTRAINED
                          placeholder: NONE
                        )
                      }
                      publicURL
                    }
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicCookiePolicyDataBodyDownload {
            id
            slice_type
            primary {
              file {
                url
                size
                localFile {
                  extension
                  publicURL
                  size
                }
              }
              label
            }
          }
          ... on PrismicCookiePolicyDataBodyTextblock {
            id
            primary {
              text {
                html
              }
              title1 {
                text
              }
            }
            slice_type
          }
        }
        meta_description
        intro {
          html
        }
        title {
          text
        }
      }
      type
      url
      alternate_languages {
        document {
          ... on PrismicCookiePolicy {
            url
          }
        }
        lang
      }
      lang
    }
  }
`;

CookiePolicy.propTypes = {
  data: PropTypes.object,
};
