import * as React from 'react'
import { PropTypes} from 'prop-types';

function humanFileSize(size) {
	if (size < 1024) return size + ' B'
	let i = Math.floor(Math.log(size) / Math.log(1024))
	let num = (size / Math.pow(1024, i))
	let round = Math.round(num)
	if (round < 10) {
		num = num.toFixed(2)
	} else {
		num = round < 100 ? num.toFixed(1) : round;
	}
	return `${num}${'KMGTPEZY'[i-1]}B`
}

export const Download = ({ data, target }) => {
	const { file, label, display_as_button, download } = data
	let file_adapted = file
	if (!file_adapted) file_adapted = download
	let extension = file_adapted?.localFile?.extension;
	let extensionString = '('+ (extension ? extension.toUpperCase()+' - ' : '') + humanFileSize(file_adapted.size ) + ')';

	return (
		<div className="download">
			<a target={target} rel={target && 'noopener noreferrer'} href={file_adapted.localFile?.publicURL || file_adapted.url} className={display_as_button ? "c-btn -primary u-mt1" : "action-save"}>{label || file_adapted.raw?.name} {extensionString}</a>
		</div>
	)
}

Download.propTypes = {
	data: PropTypes.object,
	target: PropTypes.string
}